import React from 'react';
import { Image } from 'react-bootstrap';
import bannerPro from '../../Assets/cfi/banner-guardar-dinheiro-pro.webp';
import AdsenseResponsivo0 from '../Adsense/AdsenseResponsivo0';

const AdsFixedDesktop = () => {
  const [contentVisible, setContentVisible] = React.useState(true);

  function getBannerProOrAdsense() {
    var d = new Date();
    var hora = d.getHours();

    if (hora > 19 && !window.location.href.includes('plano-pro')) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px',
            marginBottom: '5px',
          }}
        >
          <a href="/lp/guardar-dinheiro-pro">
            <Image src={bannerPro} alt="guardar dinheiro pro" />
          </a>
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px',
            marginBottom: '5px',
            // border: '1px solid #00bfff',
          }}
        >
          {/* <AdsenseFixedMobile /> */}
          {/* <AdsenseFixedDesktop /> */}
          <AdsenseResponsivo0 />
        </div>
      );
    }
  }

  React.useEffect(() => {
    var path =
      '/22523725657/guardardinheiro.com.br/Guardardinheiro_Fixed_Desktop';
    var size = [
      [336, 280],
      [728, 90],
      [970, 90],
    ];
    var id = 'Fixed_slot';

    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];
    googletag.cmd.push(function () {
      googletag
        .defineSlot(path, size, id)
        .setCollapseEmptyDiv(true)
        .addService(googletag.pubads());

      googletag.pubads().enableLazyLoad({
        fetchMarginPercent: 20,
        renderMarginPercent: 10,
        mobileScaling: 2.0,
      });

      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
    });
    googletag.cmd.push(function () {
      googletag.display(id);
    });

    const checkContentVisibility = () => {
      const contentElement = document.getElementById('Fixed_slot');
      if (contentElement) {
        setContentVisible(
          window.getComputedStyle(contentElement).display !== 'none',
        );
      }
    };

    checkContentVisibility();
    // Optionally, set up an interval or mutation observer to re-check visibility if it may change dynamically
    const intervalId = setInterval(checkContentVisibility, 2800);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div
        id="Fixed_slot"
        style={{
          textAlign: 'center',
          // display: 'inline-block',
          // width: '970px',
          // height: '90px',
          // border: '1px solid #00bfff',
          marginBottom: '5px',
        }}
      ></div>
      <div>{!contentVisible && <AdsenseResponsivo0 />}</div>
    </>
  );
};
export default AdsFixedDesktop;
