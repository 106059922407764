import React from 'react';

const AdsenseContent3SideBar = (props) => {
  React.useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '5px',
          marginBottom: '5px',
        }}
      >
        <ins
          className="adsbygoogle"
          style={{
            display: 'inline-block',
            width: '300px',
            height: '250px',
            // border: '1px solid #00bfff',
          }}
          data-ad-client="ca-pub-4615548473778420"
          data-ad-slot="8420941451"
        ></ins>
      </div>
    </>
  );
};
export default AdsenseContent3SideBar;
