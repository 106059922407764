import React from 'react';
import { Table } from 'react-bootstrap';
import { isMobile } from '../Helper/Mobile';
import Loading from '../Helper/Loading';
import styles from './Wallet.module.css';
import stylesDiv from '../../Pages/_css/Div.module.css';
import stylesTipografia from '../../Pages/_css/Tipografia.module.css';
import { AWS_GET_USER_WALLET } from '../Integracoes/Api';
import useFetch from '../../Hooks/useFetch';
import ModalWalletForm from './ModalWalletForm';
import { Pie, Bar, Doughnut } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

import {
  formataMoeda,
  formataPorcentagem,
  formataRetorno,
} from '../Helper/DateUtilities';
import AdsContent2 from '../AdsJoinAds/AdsContent2';

const WalletItem = () => {
  const [show, setShow] = React.useState(false);
  const [ticker, setTicker] = React.useState(null);
  const [tipo, setTipo] = React.useState(null);
  const [qtde, setQtde] = React.useState('0');
  const [precoMedio, setPrecoMedio] = React.useState('0');
  const [variacaoReaisDia, setVariacaoReaisDia] = React.useState(0);

  const { data, loading, error, request } = useFetch();
  const mobile = isMobile();

  React.useEffect(() => {
    const { url, options } = AWS_GET_USER_WALLET(
      window.localStorage.getItem('gd_uid'),
    );
    request(url, options);
  }, [request]);

  function getSVGSinal(valor) {
    if (valor >= 0) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          fill="#4caf50"
          className="bi bi-arrow-up-square-fill"
          viewBox="0 0 16 16"
        >
          <path d="M2 16a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2zm6.5-4.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 1 0" />
        </svg>
      );
    } else {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          fill="#fa3a3a"
          className="bi bi-arrow-down-square-fill"
          viewBox="0 0 16 16"
        >
          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm6.5 4.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5a.5.5 0 0 1 1 0" />
        </svg>
      );
    }
  }

  function getRendimentoDia() {
    var variacaoEmReais = 0;
    data.forEach((registro) => {
      variacaoEmReais =
        variacaoEmReais + parseFloat(registro.performance_reais);
    });

    return (
      <table style={{ width: '100%' }}>
        <tr style={{ border: 'none' }}>
          <th style={{ border: 'none' }}>
            RENDENDO HOJE {getSVGSinal(variacaoEmReais)}
          </th>
        </tr>
        <tr style={{ border: 'none' }}>
          <td style={{ fontSize: '1rem', fontWeight: 'bold' }}>
            {formataMoeda(variacaoEmReais)}
          </td>
        </tr>
      </table>
    );
  }

  function getUsersVariacaoDia() {
    const options = {
      scales: {
        x: { grid: { drawOnChartArea: false } },
        y: { beginAtZero: true, grid: { drawOnChartArea: false } },
      },
    };

    let dadosUser = data.sort((a, b) => a.performance - b.performance);

    var labels = [];
    var valores = [];
    var cores = [];

    dadosUser.forEach((registro) => {
      labels.push(registro.chave);
      valores.push(registro.performance.toFixed(2));
      if (registro.performance >= 0) {
        cores.push('#023020');
      } else {
        cores.push('#B22222');
      }
    });

    if (!mobile) {
      options.aspectRatio = 3;
    }

    if (mobile) {
      if (labels.length > 7) {
        options.indexAxis = 'y';
        options.aspectRatio = 0.8;
      }
    }

    if (dadosUser.length > 0) {
      var dadosGrafico = {
        labels: labels,
        datasets: [
          {
            label: 'SEUS ATIVOS',
            data: valores,
            backgroundColor: cores,
            barPercentage: 0.5,
            borderRadius: isMobile ? 5 : 4,
            fontFamily: 'Roboto, sans-serif',
          },
        ],
      };

      return (
        <>
          <Bar
            // height={!mobile ? 100 : 200}
            data={dadosGrafico}
            options={options}
          />

          <hr />

          <Table responsive triped bordered hover size="sm">
            <tr
              style={{
                background: '#133134',
                color: 'white',
                textTransform: 'uppercase',
              }}
            >
              <th
                style={{
                  padding: '5px',
                }}
              >
                Ticker
              </th>
              <th>Preço Atual</th>
              <th>Variação %</th>
              <th>Variação R$</th>
              <th>Categoria</th>
            </tr>
            {dadosUser.map((item, index) => (
              <tr>
                <td>
                  <a
                    href={
                      '/' +
                      item.tipo.toLowerCase() +
                      '/' +
                      item.chave.toLowerCase()
                    }
                    style={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      textDecoration: 'none',
                      color: '#0d6efd',
                    }}
                  >
                    {item.chave}
                  </a>
                </td>
                <td>{formataMoeda(item.preco_atual)}</td>
                <td style={{ color: cores[index] }}>
                  {formataPorcentagem(item.performance)}
                </td>
                <td style={{ color: cores[index] }}>
                  {formataMoeda(item.performance_reais)}
                </td>
                <td>{item.tipo.replace('ACAO', 'AÇÕES')}</td>
              </tr>
            ))}
          </Table>
        </>
      );
    }
  }

  function getPatrimonio() {
    let patrimonioTotal = 0;
    let patrimonioAplicado = 0;
    data.forEach((registro) => {
      patrimonioTotal += registro.preco_atual * registro.qtde;
      patrimonioAplicado += registro.preco_medio * registro.qtde;
    });

    return (
      <table style={{ width: '100%' }}>
        <tr style={{ border: 'none' }}>
          <th style={{ border: 'none' }}>SALDO</th>
          <th style={{ border: 'none' }}>
            RENDEU R${' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrows"
              viewBox="0 0 16 16"
            >
              <path d="M1.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L2.707 7.5h10.586l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L13.293 8.5H2.707l1.147 1.146a.5.5 0 0 1-.708.708z" />
            </svg>{' '}
            {' % '}
            {getSVGSinal(patrimonioTotal - patrimonioAplicado)}
          </th>
        </tr>
        <tr style={{ border: 'none' }}>
          <td style={{ fontSize: '1rem', fontWeight: 'bold' }}>
            {formataMoeda(patrimonioTotal)}
          </td>
          <td style={{ fontSize: '1rem', fontWeight: 'bold' }}>
            {formataMoeda(patrimonioTotal - patrimonioAplicado)}
            {'  '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrows"
              viewBox="0 0 16 16"
            >
              <path d="M1.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L2.707 7.5h10.586l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L13.293 8.5H2.707l1.147 1.146a.5.5 0 0 1-.708.708z" />
            </svg>
            {'  '}
            {formataPorcentagem(
              (patrimonioTotal / patrimonioAplicado - 1) * 100,
            )}
          </td>
        </tr>
      </table>
    );

    // let rentabilidade = getRentabilidadeCarteira();
    return (
      <>
        <div style={{ width: '100%', textAlign: 'center', paddingTop: '15px' }}>
          <h3 className={stylesTipografia.h3}>SALDO</h3>
          <p className={stylesTipografia.P}>{formataMoeda(patrimonioTotal)}</p>

          <h3 className={stylesTipografia.h3}>TOTAL APLICADO</h3>
          <p className={stylesTipografia.P}>
            {formataMoeda(patrimonioAplicado)}
          </p>

          <h3 className={stylesTipografia.h3}>RENDEU</h3>
          <p className={stylesTipografia.P}>
            {getSVGSinal(patrimonioTotal - patrimonioAplicado)} +
            {formataMoeda(patrimonioTotal - patrimonioAplicado)}
          </p>
          <p className={stylesTipografia.P}>
            {getSVGSinal((patrimonioTotal / patrimonioAplicado - 1) * 100)} +
            {formataRetorno((patrimonioTotal / patrimonioAplicado - 1) * 100) +
              '%'}
          </p>
        </div>
      </>
    );
  }

  // function getRentabilidadeCarteira() {
  //   let rentabilidade = 0;
  //   let patrimonioTotal = 0;

  //   data.forEach((registro) => {
  //     patrimonioTotal += registro.preco_atual * registro.qtde;
  //   });

  //   let ativoAplicado = 0;
  //   let pesoAtivoAplicado = 0;
  //   let retornoRealAtivoAplicado = 0;

  //   data.forEach((registro) => {
  //     ativoAplicado = registro.preco_atual * registro.qtde;
  //     pesoAtivoAplicado = ativoAplicado / patrimonioTotal;
  //     retornoRealAtivoAplicado = registro.retorno_mes * pesoAtivoAplicado;
  //     rentabilidade += retornoRealAtivoAplicado;
  //   });

  //   return rentabilidade;
  // }

  function getDistribuicaoTipo() {
    var labels = [];
    var valores = [];
    let patrimonio = 0;

    data.forEach((registro) => {
      patrimonio = patrimonio + registro.preco_atual * registro.qtde;
    });

    data.forEach((registro) => {
      if (labels.indexOf(registro.tipo.replace('ACAO', 'AÇÕES')) === -1) {
        labels.push(registro.tipo.replace('ACAO', 'AÇÕES'));

        let dadosTipo = data.filter((registroTipo) =>
          registroTipo.tipo.includes(registro.tipo),
        );

        var patrimonioTipo = 0;

        dadosTipo.forEach((registro) => {
          patrimonioTipo =
            patrimonioTipo + registro.preco_atual * registro.qtde;
        });

        let porcentagemTipo = (patrimonioTipo / patrimonio) * 100;

        valores.push(`${porcentagemTipo.toFixed(2)} `);
      }
    });

    if (labels.length === 0) {
      labels.push('Sem Dados');
      valores.push(100);
    }

    var dadosGrafico = {
      labels: labels,
      datasets: [
        {
          // label: 'TOta',
          data: valores,
          options: {},
          backgroundColor: ['#3CB371', '#FA8072', '#6A5ACD', '#A9A9A9'],
        },
      ],
    };

    let delayed;

    return (
      <div style={{ display: 'block' }}>
        <h2 className={stylesTipografia.h2}>DISTRIBUIÇÃO POR TIPO</h2>
        <br />
        <Doughnut
          // width={100}
          // height={!mobile ? 50 : 50}
          data={dadosGrafico}
          options={{
            aspectRatio: mobile ? 1 : 1,
            legend: {
              labels: {
                fontFamily: 'Roboto, sans-serif',
                fontColor: 'white',
              },
            },
          }}
        />
      </div>
    );
  }

  function getDividendosPorProduto(tipo) {
    let dados = data
      .filter((registro) => registro.tipo.includes(tipo))
      .sort((a, b) => a.chave.localeCompare(b.chave));
    var labels = [];
    var valores = [];

    dados.forEach((registro) => {
      labels.push(registro.chave);
      valores.push(registro.dividendo);
    });

    var dadosGrafico = {
      labels: labels,
      datasets: [
        {
          label: 'DIVIDENDOS %',
          data: valores,
          options: {
            scales: {
              x: { grid: { drawOnChartArea: false } },
              y: { beginAtZero: true },
            },
          },
          backgroundColor: '#133134',
          barPercentage: 0.3,
          borderRadius: isMobile ? 5 : 4,
          fontFamily: 'Roboto, sans-serif',
        },
      ],
    };

    return (
      <>
        <h3 className={stylesTipografia.h3}>DIVIDENDOS POR ATIVO</h3>
        <Bar
          height={!mobile ? 150 : 200}
          data={dadosGrafico}
          options={{
            responsive: true,
            maintainAspectRatio: true,
            title: {
              display: true,
              text: 'Dividendos em % p/ Ativo',
              fontSize: 20,
              fontFamily: 'Lexend, sans-serif',
            },
            legend: {
              labels: {
                fontFamily: 'Lexend, sans-serif',
              },
            },

            // cutoutPercentage: 50,
            // legend: {
            //   display: true,
            //   position: 'right',
            // },
          }}
        />
      </>
    );
  }

  function getEvolucaoPorProduto(tipo) {
    let dados = data
      .filter((registro) => registro.tipo.includes(tipo))
      .sort((a, b) => a.chave.localeCompare(b.chave));

    var labels = [];
    var valores = [];
    var cores = [];

    dados.forEach((registro) => {
      labels.push(registro.chave);
      let valor_aplicado = registro.preco_medio;
      let valor_atual = registro.preco_atual;
      let retorno = (valor_atual / valor_aplicado - 1) * 100;
      valores.push(retorno.toFixed(2));

      if (retorno >= 0) {
        cores.push('#023020');
      } else {
        cores.push('#B22222');
      }
    });

    var dadosGrafico = {
      labels: labels,
      datasets: [
        {
          label: 'RETORNO %',
          data: valores,
          options: {
            scales: {
              x: { grid: { drawOnChartArea: false } },
              y: { beginAtZero: true },
            },
          },
          backgroundColor: cores,
          barPercentage: 0.3,
          borderRadius: isMobile ? 5 : 4,
          fontFamily: 'Roboto, sans-serif',
        },
      ],
    };

    return (
      <>
        <h3 className={stylesTipografia.h3}>RETORNO DOS ATIVOS NA CARTEIRA</h3>
        <Bar
          // width={100}
          height={!mobile ? 150 : 200}
          data={dadosGrafico}
          options={{
            responsive: true,
            maintainAspectRatio: true,

            legend: {
              labels: {
                fontFamily: 'Lexend, sans-serif',
              },
            },
          }}
        />
      </>
    );
  }

  function getDistribuicaoSetor(tipo) {
    let dados = data.filter((registro) => registro.tipo.includes(tipo));

    var labels = [];
    var valores = [];
    var valoresMoeda = [];
    let patrimonio = 0;

    dados.forEach((registro) => {
      patrimonio = patrimonio + registro.preco_atual * registro.qtde;
    });

    dados.forEach((registro) => {
      if (labels.indexOf(registro.setor.toLowerCase()) === -1) {
        labels.push(registro.setor.toLowerCase());

        let dadosSetores = dados.filter((registroSetor) =>
          registroSetor.setor.includes(registro.setor),
        );

        var patrimonioSetor = 0;

        dadosSetores.forEach((registro) => {
          patrimonioSetor =
            patrimonioSetor + registro.preco_atual * registro.qtde;
        });

        let porcentagemSetor = (patrimonioSetor / patrimonio) * 100;

        valoresMoeda.push(`${patrimonioSetor.toFixed(2)} `);
        valores.push(`${porcentagemSetor.toFixed(2)} `);
      }
    });

    let height = 200;

    if (labels.length >= 10) {
      if (mobile) {
        height = 315;
      } else {
        height = 200;
      }
    } else {
      if (mobile) {
        height = 200;
      } else {
        height = 160;
      }
    }

    var dadosGrafico = {
      labels: labels,
      datasets: [
        {
          label: 'Setores',
          data: valores,
          options: {},
          backgroundColor: [
            '#28a745',
            '#6A5ACD',
            '#A9A9A9',
            '#008B8B',
            '#FA8072',
            'rgba(255, 206, 86, 1)',
            '#00FF00',
            '#873600',
            'rgba(75, 192, 192, 1)',
            '#F0E68C',
            'black',
            '#DE3163',
            '#FFFF00',
          ],
          // borderColor: [
          //   'rgba(255, 99, 132, 1)',
          //   'rgba(54, 162, 235, 1)',
          //   'rgba(255, 206, 86, 1)',
          //   'rgba(75, 192, 192, 1)',
          //   'rgba(153, 102, 255, 1)',
          //   'rgba(255, 159, 64, 1)',
          // ],
          // borderWidth: 1,
        },
      ],
    };

    return (
      <div style={{ display: 'block', width: '100%' }}>
        <h3 className={stylesTipografia.h3}>DISTRIBUIÇÃO POR SETOR</h3>
        <Table responsive triped bordered hover size="sm">
          <tr
            style={{
              background: '#133134',
              color: 'white',
              textTransform: 'uppercase',
            }}
          >
            <th
              style={{
                padding: '5px',
              }}
            >
              Setor
            </th>
            <th>Saldo no Setor</th>
            <th>% no Setor</th>
          </tr>
          {labels.map((item, index) => (
            <tr>
              <td>{labels[index].toUpperCase()}</td>
              <td>{formataMoeda(valoresMoeda[index])}</td>
              <td>{formataPorcentagem(valores[index])}</td>
            </tr>
          ))}
        </Table>
        <Pie
          // width={100}

          height={height}
          data={dadosGrafico}
          options={{
            aspectRatio: mobile ? 1 : 4,
            legend: {
              labels: {
                fontFamily: 'Roboto, sans-serif',
                fontColor: 'white',
              },
            },
          }}
        />
      </div>
    );
  }

  function getValor(valor) {
    if (valor.includes('-')) {
      return <td className={styles.tdNegativo}>{valor}</td>;
    } else {
      return <td className={styles.tdPositivo}>{valor}</td>;
    }
  }

  function get_rv(tipo) {
    let dados = data
      .filter((registro) => registro.tipo.includes(tipo))
      .sort((a, b) => a.chave.localeCompare(b.chave));

    return (
      <>
        {/* <Table
          responsive
          triped
          bordered
          hover
          size="sm"
          className={stylesTable.tabela}
        >
          <tr>
            <th>Ativo</th>
            {!mobile ? <th>Preço Médio</th> : <th>P.M</th>}
            <th>Qtde.</th>
            {!mobile ? <th>Total Aplicado</th> : null}
            <th>Preço Atual</th>
            {!mobile ? <th>Total Atual</th> : null}
            {!mobile ? <th>Retorno R$</th> : null}
            <th>Retorno %</th>
            {!mobile ? <th>Setor</th> : null}
            <th colSpan={2}>Editar</th>
          </tr>

          {dados.map((registro) => (
            <tr key={'tr-' + registro.chave} registro={registro}>
              <td id={'chave-' + registro.chave}>{registro.chave}</td>
              <td id={registro.chave + '-pm-' + registro.preco_medio}>
                {formataMoeda(registro.preco_medio)}
              </td>
              <td id={registro.chave + '-qtde-' + registro.qtde}>
                {registro.qtde}
              </td>
              {!mobile ? (
                <td>{formataMoeda(registro.qtde * registro.preco_medio)}</td>
              ) : null}
              <td>{formataMoeda(registro.preco_atual)}</td>
              {!mobile ? (
                <td>{formataMoeda(registro.qtde * registro.preco_atual)}</td>
              ) : null}
              {!mobile
                ? getValor(
                    formataMoeda(
                      registro.preco_atual * registro.qtde -
                        registro.preco_medio * registro.qtde,
                    ),
                  )
                : null}

              {getValor(
                formataRetorno(
                  (registro.preco_atual / registro.preco_medio - 1) * 100,
                ) + '%',
              )}

              {!mobile ? <td>{registro.setor.toLowerCase()}</td> : null}
              <td>
                <button>
                  <FontAwesomeIcon
                    icon={faPen}
                    onClick={(e) =>
                      editFavoritos(
                        e,
                        registro.chave,
                        registro.qtde,
                        registro.preco_medio,
                        registro.tipo,
                      )
                    }
                  />
                </button>
                <button>
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={(e) => {
                      if (
                        window.confirm(
                          'Tem certeza em Excluir o Ativo ' +
                            registro.chave +
                            '?',
                        )
                      ) {
                        removeFavoritos(e, registro.chave);
                      }
                    }}
                  />
                </button>
              </td>
            </tr>
          ))}
        </Table>
        <hr /> */}
        <Table responsive triped bordered hover size="sm">
          <tr
            style={{
              background: '#133134',
              color: 'white',
              textTransform: 'uppercase',
            }}
          >
            <th
              style={{
                padding: '5px',
              }}
            >
              Ativo
            </th>
            <th>Preço</th>
            <th>Retorno</th>
            {/* <th>Setor</th> */}
            <th colSpan={2}></th>
          </tr>

          {dados.map((registro) => (
            <tr key={'tr-' + registro.chave} registro={registro}>
              <td id={'chave-' + registro.chave}>
                <div style={{ display: 'block' }}>
                  <div>
                    <a
                      href={
                        '/' +
                        registro.tipo.toLowerCase() +
                        '/' +
                        registro.chave.toLowerCase()
                      }
                      style={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        textDecoration: 'none',
                      }}
                    >
                      {registro.chave}
                    </a>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>
                      <strong>Qtde: </strong>
                      {registro.qtde}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>
                      <strong>Div.Yield:</strong>
                      {formataPorcentagem(registro.dividendo)}
                    </span>
                  </div>

                  <span className={stylesTipografia.spanTooltip}>
                    Setor{' '}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      fill="currentColor"
                      className="bi bi-info-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg>
                    <span className={stylesTipografia.tooltiptext}>
                      {registro.chave + ' - ' + registro.setor}
                    </span>
                  </span>
                </div>
              </td>

              <td id={registro.chave + '-pm-' + registro.preco_medio}>
                <div style={{ display: 'block' }}>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>Preço.Médio</span>
                  </div>
                  <div style={{ borderBottom: '1px solid black' }}>
                    {formataMoeda(registro.preco_medio)}
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>Preço.Atual</span>
                  </div>
                  <div>{formataMoeda(registro.preco_atual)}</div>
                </div>
              </td>

              {/* <td>{formataMoeda(registro.qtde * registro.preco_medio)}</td> */}

              <td id={registro.chave + '-pm-' + registro.preco_medio}>
                <div style={{ display: 'block' }}>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>Saldo</span>
                  </div>
                  <div style={{ borderBottom: '1px solid black' }}>
                    {formataMoeda(registro.preco_atual * registro.qtde)}
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>
                      Rendeu %{' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrows"
                        viewBox="0 0 16 16"
                      >
                        <path d="M1.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L2.707 7.5h10.586l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L13.293 8.5H2.707l1.147 1.146a.5.5 0 0 1-.708.708z" />
                      </svg>{' '}
                      R$
                    </span>
                  </div>

                  <div>
                    {(registro.preco_atual / registro.preco_medio - 1) * 100 >=
                    0 ? (
                      <span style={{ color: 'green' }}>
                        {formataRetorno(
                          (registro.preco_atual / registro.preco_medio - 1) *
                            100,
                        ) + '%'}
                        {'  '}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrows"
                          viewBox="0 0 16 16"
                        >
                          <path d="M1.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L2.707 7.5h10.586l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L13.293 8.5H2.707l1.147 1.146a.5.5 0 0 1-.708.708z" />
                        </svg>
                        {'  '}
                        {formataMoeda(
                          registro.preco_atual * registro.qtde -
                            registro.preco_medio * registro.qtde,
                        )}
                      </span>
                    ) : (
                      <span style={{ color: 'red' }}>
                        {formataRetorno(
                          (registro.preco_atual / registro.preco_medio - 1) *
                            100,
                        ) + '%'}
                        {'  '}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrows"
                          viewBox="0 0 16 16"
                        >
                          <path d="M1.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L2.707 7.5h10.586l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L13.293 8.5H2.707l1.147 1.146a.5.5 0 0 1-.708.708z" />
                        </svg>
                        {'  '}
                        {formataMoeda(
                          registro.preco_atual * registro.qtde -
                            registro.preco_medio * registro.qtde,
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </td>

              {/* <td>{registro.setor.toLowerCase()}</td> */}
              <td>
                <button>
                  <FontAwesomeIcon
                    icon={faPen}
                    onClick={(e) =>
                      editFavoritos(
                        e,
                        registro.chave,
                        registro.qtde,
                        registro.preco_medio,
                        registro.tipo,
                      )
                    }
                  />
                </button>
                <button>
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={(e) => {
                      if (
                        window.confirm(
                          'Tem certeza em Excluir o Ativo ' +
                            registro.chave +
                            '?',
                        )
                      ) {
                        removeFavoritos(e, registro.chave);
                      }
                    }}
                  />
                </button>
              </td>
            </tr>
          ))}
        </Table>
      </>
    );
  }

  function get_rv_agrupado(tipo, titulo) {
    let dados = data.filter((registro) => registro.tipo.includes(tipo));

    let totalAplicado = 0;
    let totalAtual = 0;
    let retornoReais = 0;
    let retorno = 0;

    dados.forEach((registro) => {
      totalAplicado += registro.preco_medio * registro.qtde;
      totalAtual += registro.preco_atual * registro.qtde;
    });
    retornoReais = totalAtual - totalAplicado;
    retorno = (totalAtual / totalAplicado - 1) * 100;

    // return (
    //   <>
    //     <div style={{ width: '100%', textAlign: 'center', paddingTop: '15px' }}>
    //       <h3 className={stylesTipografia.h3}>SALDO</h3>
    //       <p className={stylesTipografia.P}>{formataMoeda(totalAtual)}</p>

    //       <h3 className={stylesTipografia.h3}>TOTAL APLICADO</h3>
    //       <p className={stylesTipografia.P}>{formataMoeda(totalAplicado)}</p>

    //       <h3 className={stylesTipografia.h3}>RENDEU</h3>
    //       <p className={stylesTipografia.P}>
    //         {getSVGSinal(retornoReais)} +{formataMoeda(retornoReais)}
    //       </p>
    //       <p className={stylesTipografia.P}>
    //         {getSVGSinal(retorno)} +{formataRetorno(retorno) + '%'}
    //       </p>
    //     </div>
    //   </>
    // );

    return (
      <table style={{ width: '100%' }}>
        <tr style={{ border: 'none' }}>
          <th style={{ border: 'none' }}>SALDO</th>
          <th style={{ border: 'none' }}>
            RENDEU R${' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrows"
              viewBox="0 0 16 16"
            >
              <path d="M1.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L2.707 7.5h10.586l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L13.293 8.5H2.707l1.147 1.146a.5.5 0 0 1-.708.708z" />
            </svg>{' '}
            {' % '}
            {getSVGSinal(retornoReais)}
          </th>
        </tr>
        <tr style={{ border: 'none' }}>
          <td style={{ fontSize: '1rem', fontWeight: 'bold' }}>
            {formataMoeda(totalAtual)}
          </td>
          <td style={{ fontSize: '1rem', fontWeight: 'bold' }}>
            {formataMoeda(retornoReais)}
            {'  '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrows"
              viewBox="0 0 16 16"
            >
              <path d="M1.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L2.707 7.5h10.586l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L13.293 8.5H2.707l1.147 1.146a.5.5 0 0 1-.708.708z" />
            </svg>
            {'  '}
            {formataPorcentagem(retorno)}
          </td>
        </tr>
      </table>
    );
  }

  const editFavoritos = (event, chave, qtde, preco_medio, tipo) => {
    event.preventDefault();

    // console.log(chave);
    // console.log(qtde);
    // console.log(preco_medio);

    setTicker(chave);
    setTipo(tipo);
    setPrecoMedio(formataMoeda(preco_medio));
    setQtde(qtde);

    setShow(true);
    // console.log(show);
  };

  const removeFavoritos = (event, chave) => {
    event.preventDefault();

    // setShow(true);
    // console.log(show);
    deleteItem(window.localStorage.getItem('gd_uid'), chave, 'DELETE');
  };

  function deleteItem(user_id, chave, http_method) {
    const urlPostPortfolio = `https://oe8p7mxbw5.execute-api.sa-east-1.amazonaws.com/producao/user-wallet?user_id=${user_id}&chave=${chave}&metodo=${http_method}`;
    // console.log(urlPostPortfolio);

    const requestOptionsPost = {
      method: 'DELETE',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(urlPostPortfolio, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);
          if (http_method === 'DELETE') {
            window.location.reload(false);
          }
          // return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  if (loading) return <Loading />;
  if (data) {
    return (
      <>
        {/* <MercadoDia pagina="dashboard" /> */}

        <details className={styles.details} close>
          <summary className={styles.summary}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-bar-chart"
              viewBox="0 0 16 16"
            >
              <path d="M4 11H2v3h2zm5-4H7v7h2zm5-5v12h-2V2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1z" />
            </svg>{' '}
            SEUS ATIVOS - VARIAÇÃO DO DIA
            {getRendimentoDia()}
          </summary>
          {getUsersVariacaoDia()}
        </details>

        <details className={styles.details} close>
          <summary className={styles.summary}>
            MEUS INVESTIMENTOS{getPatrimonio()}
          </summary>
          <div className={styles.containerPatrimonio}>
            {getDistribuicaoTipo()}
          </div>
          {/* <div className={styles.containerPatrimonio}>
            
          </div> */}
        </details>

        <br />
        <AdsContent2 />
        <br />

        <div className={stylesDiv.divSombra}>
          {data.filter((registro) => registro.tipo.includes('ACAO')).length >
          0 ? (
            <details className={styles.details} close>
              <summary className={styles.summary}>
                AÇÕES
                {get_rv_agrupado('ACAO')}
              </summary>

              {get_rv('ACAO')}
              <div className={styles.container}>
                {/* <div className={styles.item}>{getDistribuicaoSetor('FII')}</div> */}

                <div className={styles.item}>
                  {getEvolucaoPorProduto('ACAO')}
                </div>

                <div className={styles.item}>
                  {getDividendosPorProduto('ACAO')}
                </div>

                <div className={styles.item}>
                  {getDistribuicaoSetor('ACAO')}
                </div>
              </div>
            </details>
          ) : null}

          {data.filter((registro) => registro.tipo.includes('FII')).length >
          0 ? (
            <details className={styles.details} close>
              <summary className={styles.summary}>
                FUNDOS IMOBILIÁRIOS
                {get_rv_agrupado('FII')}
              </summary>

              {get_rv('FII')}
              <div className={styles.container}>
                {/* <div className={styles.item}>{getDistribuicaoSetor('FII')}</div> */}

                <div className={styles.item}>
                  {getEvolucaoPorProduto('FII')}
                </div>

                <div className={styles.item}>
                  {getDividendosPorProduto('FII')}
                </div>

                <div className={styles.item}>{getDistribuicaoSetor('FII')}</div>
              </div>
            </details>
          ) : null}

          {data.filter((registro) => registro.tipo.includes('ETF')).length >
          0 ? (
            <details className={styles.details} close>
              <summary className={styles.summary}>
                ETF{get_rv_agrupado('ETF')}
              </summary>

              {get_rv('ETF')}
              <div className={styles.container}>
                <div className={styles.item}>
                  {getEvolucaoPorProduto('ETF')}
                </div>
              </div>
            </details>
          ) : null}

          {data.filter((registro) => registro.tipo.includes('BDR')).length >
          0 ? (
            <details className={styles.details} close>
              <summary className={styles.summary}>
                BDR
                {get_rv_agrupado('BDR')}
              </summary>

              {get_rv('BDR')}
              <div className={styles.container}>
                {/* <div className={styles.item}>{getDistribuicaoSetor('FII')}</div> */}

                <div className={styles.item}>
                  {getEvolucaoPorProduto('BDR')}
                </div>

                <div className={styles.item}>
                  {getDividendosPorProduto('BDR')}
                </div>

                <div className={styles.item}>{getDistribuicaoSetor('BDR')}</div>
              </div>
            </details>
          ) : null}
        </div>

        <br />

        {show && (
          <ModalWalletForm
            onClose={() => setShow(false)}
            ticker={ticker}
            tipo={tipo}
            qtde={qtde}
            preco_medio={precoMedio}
            show={show}
          />
        )}
      </>
    );
  } else {
    return null;
  }
};

export default WalletItem;
