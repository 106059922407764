import React from 'react';
import { Container, Table } from 'react-bootstrap';
import styles from './Wallet.module.css';
import stylesTipografia from '../../Pages/_css/Tipografia.module.css';
import stylePost from '../../Components/Tags/Post.module.css';
import stylesButton from '../../Pages/_css/Button.module.css';
import WalletItem from './WalletItem';
import ModalCadastroAtivo from './ModalCadastroAtivo';
import Head from '../Tags/Head';
import BreadcumbH1 from '../Tags/BreadcumbH1';
import isMobile from '../Helper/Mobile';
import Categorias from '../Categorias/Categorias';
import AdsFixedDesktop from '../AdsJoinAds/AdsFixedDesktop';
import AdsAmpContent1 from '../AdsJoinAds/AdsAmpContent1';

const Wallet = () => {
  const mobile = isMobile();
  const [show, setShow] = React.useState(false);
  const [tipo, setTipo] = React.useState(null);

  const showModal = (event, tipo) => {
    event.preventDefault();
    setShow(true);
    setTipo(tipo);
  };

  return (
    <>
      <Head
        metaDescription="Guardar Dinheiro é um portal gratuito que oferece informações do mercado financeiro que ajuda os investidores a escolher o melhor investimento do momento."
        title="Meus Investimentos | Guardar Dinheiro"
        h1="Guardar Dinheiro"
        canonical="https://www.guardardinheiro.com.br/dashboard"
        nivel="1"
        id_wp_post={2}
      />

      <BreadcumbH1
        paginaAtual="Meus Investimentos"
        texto="Meus Investimentos"
      />

      <Container>
        {mobile ? <AdsAmpContent1 /> : <AdsFixedDesktop />}
        <div className={stylePost.container}>
          <div className={stylePost.itemPost}>
            <h1 className={stylesTipografia.h1}>Meus Investimentos</h1>

            <h2 className={stylesTipografia.h2}>
              MINHA CARTEIRA DE INVESTIMENTOS
            </h2>

            <p>
              Escolha o produto abaixo para adicionar em sua Carteira de
              Investimentos.
            </p>
            <div className={styles.containerAddProdutos}>
              <button
                className={stylesButton.button}
                style={{ width: '175px', fontSize: '14px' }}
                onClick={(e) => showModal(e, 'acoes')}
              >
                Ação
              </button>
              <button
                className={stylesButton.button}
                style={{ width: '175px', fontSize: '14px' }}
                onClick={(e) => showModal(e, 'fii')}
              >
                Fundo Imobiliário
              </button>
              <button
                className={stylesButton.button}
                style={{ width: '175px', fontSize: '14px' }}
                onClick={(e) => showModal(e, 'etf')}
              >
                ETF
              </button>
              <button
                className={stylesButton.button}
                style={{ width: '175px', fontSize: '14px' }}
                onClick={(e) => showModal(e, 'bdr')}
              >
                BDR
              </button>
            </div>

            <WalletItem />

            {/* <AdsenseParagrafo5 /> */}
            {show && (
              <ModalCadastroAtivo
                onClose={() => setShow(false)}
                tipo={tipo}
                show={show}
              />
            )}
          </div>
          {!mobile ? (
            <div className={stylePost.itemCategorias}>
              <Categorias />
            </div>
          ) : null}
        </div>

        {mobile && (
          <>
            <hr />
            <div className={stylePost.itemCategorias}>
              <Categorias />
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default Wallet;
