import React from 'react';
import { Container, Image, Table, Accordion, Alert } from 'react-bootstrap';
import styles from './LpControleFinanceiro.module.css';
import Whatsapp from '../../Components/Helper/Whatsapp';
import mockup from '../../Assets/cfi/mockup.webp';
import sonhos from '../../Assets/cfi/sonhos.webp';
import garantia from '../../Assets/cfi/7-dias-de-garantia.webp';
import cartoes from '../../Assets/cfi/formas-pagamento.webp';
import cadastroConta from '../../Assets/cfi/telas/cadastro-contas.webp';
import cadastroCategoria from '../../Assets/cfi/telas/cadastro-categorias.webp';
import cadastroCartoes from '../../Assets/cfi/telas/cadastro-cartoes.webp';
import orcamento from '../../Assets/cfi/telas/orcamento.webp';
import orcamento2 from '../../Assets/cfi/telas/orcamento2.webp';
import resumoMensal from '../../Assets/cfi/telas/resumo-mensal.webp';
import resumoMensal2 from '../../Assets/cfi/telas/resumo-mensal2.webp';
import resumoMensal3 from '../../Assets/cfi/telas/resumo-mensal3.webp';
import resumoMensal4 from '../../Assets/cfi/telas/resumo-mensal4.webp';
import resumoAnual from '../../Assets/cfi/telas/resumo-anual.webp';
import resumoAnual2 from '../../Assets/cfi/telas/resumo-anual2.webp';
import dividas from '../../Assets/cfi/telas/dividas.webp';
import cadastroDespesa from '../../Assets/cfi/telas/cadastro-despesa.webp';
import cadastro from '../../Assets/cfi/telas/cadastro.webp';
import cadastroMetas from '../../Assets/cfi/telas/cadastro-metas.webp';
import extrato from '../../Assets/cfi/telas/extrato.webp';
import patrimonio from '../../Assets/cfi/telas/patrimonio.webp';
import aula from '../../Assets/cfi/telas/video-aula.webp';
import mockup3d from '../../Assets/cfi/mockup-3d.webp';
import Carousel from 'react-bootstrap/Carousel';
import Footer from '../../Footer/Footer';
// import isMobile from '../../Components/Helper/Mobile';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';

const LpControleFinanceiro = () => {
  // const sampleData = [];
  // const [showComponent, setShowComponent] = useState(false);
  // const [seconds, setSeconds] = useState(1);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setSeconds((seconds) => seconds + 1);
  //   }, 4000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  React.useEffect(() => {
    window.localStorage.setItem('gd_pro_popup', '1');

    if (typeof window.useShortlink === 'function') {
      window.useShortlink(
        'https://www.guardardinheiro.com.br/lp/guardar-dinheiro-pro/?p=9013',
      );
    }
    // window.gtag('event', 'banner_pro');
  });

  function getButton(texto, action) {
    var link = '';
    if (action === 'suporte') {
      link =
        'https://api.whatsapp.com/send?phone=5511913569157&text=Ol%C3%A1%2C%20gostaria%20de%20tirar%20dúvidas%20sobre%20o%20Guardar%20Dinheiro-PRO%3F';
    } else if (action === 'sessao-preco') {
      link = '#precos';
    } else if (action === 'sessao-youtube') {
      link = '#video-youtube';
    } else {
      link = 'https://app.monetizze.com.br/checkout/DPE233437';
    }
    return (
      <Container>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <a href={link} className={styles.buttonQuero}>
            {texto}
          </a>
        </div>
      </Container>
    );
  }

  function getSVG(tipo) {
    if (tipo === 'check') {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#28a745"
          className="bi bi-check-square-fill"
          viewBox="0 0 16 16"
        >
          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
        </svg>
      );
    } else if (tipo === 'alert') {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#28a745"
          className="bi bi-exclamation-triangle-fill"
          viewBox="0 0 16 16"
        >
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </svg>
      );
    } else if (tipo === 'seta-direita') {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="#28a745"
          className="bi bi-arrow-right-circle-fill"
          viewBox="0 0 16 16"
        >
          <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
        </svg>
      );
    }
  }

  function returnFiveStars() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '5px',
          marginTop: '5px',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#FFD700"
          className="bi bi-star-fill"
          viewBox="0 0 16 16"
        >
          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#FFD700"
          className="bi bi-star-fill"
          viewBox="0 0 16 16"
        >
          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#FFD700"
          className="bi bi-star-fill"
          viewBox="0 0 16 16"
        >
          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#FFD700"
          className="bi bi-star-fill"
          viewBox="0 0 16 16"
        >
          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#FFD700"
          className="bi bi-star-fill"
          viewBox="0 0 16 16"
        >
          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        </svg>
      </div>
    );
  }

  function getSection1() {
    return (
      <section id="secao1">
        <div className={styles.divMaster1}>
          <Container>
            <div className={styles.parent}>
              <div className={styles.child}>
                <div className={styles.childchild}>
                  <h1 className={styles.h1}>GUARDAR DINHEIRO</h1>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={styles.svg}
                    fill="#28a745"
                  >
                    <filter
                      id="motion-blur-filter"
                      filterUnits="userSpaceOnUse"
                    >
                      <feGaussianBlur stdDeviation="100 0"></feGaussianBlur>
                    </filter>
                  </svg>

                  <span className={styles.spanPro} filter-content="S">
                    Pro
                  </span>
                  <h2 className={styles.h2}>
                    Ferramentas especializadas Para Você Sair das Dívidas e
                    Conseguir Fazer o Seu Dinheiro Sobrar e Render Todo Mês
                  </h2>
                </div>
              </div>
              <div className={styles.child}>
                <Image
                  width="413"
                  height="400"
                  // src="https://blogguardardinheiro.s3.sa-east-1.amazonaws.com/mockup.webp"
                  src={mockup}
                  alt=" telas guardar dinheiro pro"
                  // loading="lazy"
                  // srcset="https://blogguardardinheiro.s3.sa-east-1.amazonaws.com/mockup.webp 614w, https://blogguardardinheiro.s3.sa-east-1.amazonaws.com/mockup.webp 300w"
                  sizes="60vw"
                />
              </div>
            </div>
          </Container>
        </div>
      </section>
    );
  }

  function getSectionQtdeFerramentasOld() {
    return (
      <section id="secao-qtde-ferramenta">
        <div style={{ backgroundColor: '#f7f7f7' }}>
          <Container>
            <div className={styles.parent}>
              <div className={styles.child}>
                <div className={styles.divH3}>
                  <h3 className={styles.h3}>GUARDAR DINHEIRO PRO</h3>
                  <p className={styles.p}>
                    Você terá acesso a nova ferramenta com funcionalidades que
                    vai te proporcionar o{' '}
                    <strong>total controle das suas finanças.</strong>
                  </p>
                  <p className={styles.p}>
                    <strong>
                      Esqueça planilhas!!! No GUARDAR DINHEIRO PRO
                    </strong>
                    , você vai conseguir registrar todas as suas informações
                    financeiras de forma prática, rápida e fácil, conseguindo
                    assim ter uma{' '}
                    <strong>
                      visão completa das suas finanças em um só lugar.
                    </strong>
                  </p>
                  {/* <p className={styles.p}>
                    Com o <strong>GUARDAR DINHEIRO PRO</strong> você vai
                    conseguir saber para onde seu dinheiro está indo
                    detalhadamente. Basta você registrar sua receita ou despesa,
                    que o sistema fará o{' '}
                    <strong>
                      mapeamento de sua vida financeira automaticamente.
                    </strong>
                  </p> */}
                </div>
              </div>
              <div className={styles.child}>
                <Image
                  src={mockup3d}
                  className={styles.box}
                  alt="telas guardar dinheiro pro"
                />
              </div>
            </div>
          </Container>
        </div>
      </section>
    );
  }

  function getSectionQtdeFerramentas() {
    return (
      <section id="secao-qtde-ferramenta">
        <div style={{ backgroundColor: '#f7f7f7' }}>
          <Container>
            <div className={styles.parent}>
              <div className={styles.childSessao1}>
                <h3 className={styles.h1}>GUARDAR DINHEIRO PRO</h3>

                <h2 className={styles.h2}>
                  O Site para organizar suas finanças e fazer o seu dinheiro
                  render todo mês.
                </h2>
                {/* <br /> */}
                <p className={styles.h2}>
                  Gerencie seus gastos de forma automática e veja seu saldo
                  positivo no final do mês.
                </p>
              </div>
              <div className={styles.childSessao2}>
                <Image
                  src={mockup3d}
                  className={styles.box}
                  alt="telas guardar dinheiro pro"
                />
              </div>
            </div>
          </Container>
        </div>
      </section>
    );
  }

  function getSectionSalario() {
    return (
      <section id="salario">
        <div>
          <div className={styles.parent}>
            <div className={styles.childRight}>
              <h3 className={styles.h3}>Mesmo que você Ganhe</h3>
            </div>
            <div className={styles.childLeft}>
              <ul className={styles.ul}>
                <li>
                  {getSVG('seta-direita')}
                  {'     mil reais por mês...'}
                </li>
                <li>
                  {getSVG('seta-direita')}
                  {'     3 mil reais por mês...'}
                </li>
                <li>
                  {getSVG('seta-direita')}
                  {'     5 mil reais por mês ou mais...'}
                </li>
              </ul>
            </div>
            <div style={{ textAlign: 'center' }}>
              <h4 className={styles.h4}>
                Você também pode organizar suas finanças
              </h4>
            </div>
          </div>
        </div>
      </section>
    );
  }

  function getSectionPraQuem() {
    return (
      <section id="para-quem">
        <div style={{ textAlign: 'center' }}>
          <h3 className={styles.h3} style={{ color: '#2c2c2c' }}>
            Pra quem é o GUARDAR DINHEIRO PRO?
          </h3>
        </div>
        <Container>
          <ul className={styles.checkList}>
            <li>
              &nbsp;Para você que está cansado de trabalhar só para pagar as
              contas e quer alcançar a independência financeira
            </li>
            {/* <li className={styles.liResp}>
            &nbsp;&nbsp;&nbsp;84% dos brasileiros estão lutando para pagar as
            contas e no fim do mês não sobra nada!
          </li> */}

            <li>
              &nbsp;Para você que sempre se pergunta onde seu dinheiro foi parar
            </li>
            {/* <li className={styles.liResp}>
            &nbsp;&nbsp;&nbsp;O principal motivo de endividamento entre os
            brasileiros é a falta de controle financeiro.
          </li> */}

            <li>
              &nbsp;Para você que tem dificuldades em acompanhar suas despesas e
              receitas mensais
            </li>
            {/* <li className={styles.liResp}>
            &nbsp;&nbsp;&nbsp;88% dos brasileiros já tiveram dificuldade para
            dormir por estarem endividados a atualmente segundo a Agência
            Brasil, 78,9% das famílias brasileiras estão endividadas.
          </li> */}
            <li>
              &nbsp;Para você que tem dificuldades em atingir metas financeiras
            </li>
            <li>
              &nbsp;Para você que não consegue criar uma reserva de emergência,
              pois sem ela você está vulnerável a imprevistos financeiros, como
              despesas médicas ou perda de emprego
            </li>
            <li>
              &nbsp;Para você que precisa identificar áreas onde pode economizar
              e destinar uma parte do seu dinheiro para investimentos
            </li>
            <li>
              &nbsp;Para você poder planejar seu futuro financeiro com mais
              eficiência, incluindo economizar para aposentadoria, planejar a
              compra de uma casa, planejar a educação dos filhos e outras metas
              de longo prazo
            </li>
            <li>
              &nbsp;Para você que tem dificuldade em alcançar a independência
              financeira, pois sem um controle financeiro pessoal eficaz, pode
              ser difícil alcançar a independência financeira e a estabilidade a
              longo prazo
            </li>
            <li>
              &nbsp;Para você que possui milhões de planilhas, com informações
              separadas em vários lugares diferentes
            </li>
          </ul>
        </Container>
      </section>
    );
  }

  function getSectionReceber2() {
    return (
      <section id="receber2">
        <div style={{ textAlign: 'center' }}>
          <h3 className={styles.h3}>
            <span className={styles.span}>O que você </span>vai receber?
          </h3>

          <div className={styles.parentFerramentas}>
            <div className={styles.childFerramentas}>
              <h2 className={styles.h2Ferramentas}>
                {getSVG('check')} Registro de Despesas, Receitas e Investimentos
              </h2>
              <p className={styles.p}>
                Registre e organizar suas despesas no GUARDAR DINHEIRO PRO, que
                proporcionamos uma visão clara e precisa de suas finanças
                pessoais.
              </p>
            </div>

            <div className={styles.childFerramentas}>
              <h2 className={styles.h2Ferramentas}>
                {getSVG('check')} Controle de Dívidas
              </h2>
              <p className={styles.p}>
                Identifique todas as suas dívidas no GUARDAR DINHEIRO PRO, e
                tenha o acompanhamento da redução da sua dívidfa, e caminhe em
                direção à paz e liberdade financeira.
              </p>
            </div>

            <div className={styles.childFerramentas}>
              <h2 className={styles.h2Ferramentas}>
                {getSVG('check')} Controle de Contas Correntes
              </h2>
              <p className={styles.p}>
                Tenha o mapeamento de todas as suas contas correntes no Guardar
                Dinheiro PRO, e confira detalhadamente todas as entradas e
                saídas de cada conta.
              </p>
            </div>

            <div className={styles.childFerramentas}>
              <h2 className={styles.h2Ferramentas}>
                {getSVG('check')} Controle de Cartão de Crédito
              </h2>
              <p className={styles.p}>
                Nunca mais estoure o limite dos seus cartões do crédito, pois
                com o GUARDAR DINHEIRO PRO, você vai ter uma visão de todos os
                seus gastos com cartão.
              </p>
            </div>

            <div className={styles.childFerramentas}>
              <h2 className={styles.h2Ferramentas}>
                {getSVG('check')} Controle por Categoria
              </h2>
              <p className={styles.p}>
                No GUARDAR DINHEIRO PRO, você consegue identificar com precisão,
                quais categorias estãoconsumindo a maior parte do seu orçamento,
                permitindo que você faça ajustes e priorize seus gastos de forma
                mais eficiente.
              </p>
            </div>

            <div className={styles.childFerramentas}>
              <h2 className={styles.h2Ferramentas}>
                {getSVG('check')} Plenejamento Financeiro de Gastos Essenciais e
                Não Essenciais
              </h2>
              <p className={styles.p}>
                Crie um orçamento de gastos no GUARDAR DINHEIRO PRO e monitore
                seus gastos essenciais e não essenciais.
              </p>
            </div>

            <div className={styles.childFerramentas}>
              <h2 className={styles.h2Ferramentas}>
                {getSVG('check')} Planejamento de Reserva de Emergência
              </h2>
              <p className={styles.p}>
                Não sabe quanto você precisa ter na Reserva de Emergência. O
                Guadar Dinheiro PRO calcula automaticamente quanto você precisa,
                de acordo com seus gastos mensais.
              </p>
            </div>

            <div className={styles.childFerramentas}>
              <h2 className={styles.h2Ferramentas}>
                {getSVG('check')} Patrimônio - Riqueza
              </h2>
              <p className={styles.p}>
                Monitore seu patrimônio ao longo do tempo no GUARDAR DINHEIRO
                PRO e confira a evolução do seu patrimônio mensalmente.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  function getSectionSonho() {
    return (
      <section id="sonho">
        <div className={styles.parent}>
          <div className={styles.child}>
            <div style={{ display: 'block' }}>
              <p className={styles.pFuncionalidades}>
                Para toda e qualquer pessoa que está em busca do seu controle
                financeiro para deixar de viver atolado em dívidas, e assim
                conseguir fazer com que sobre dinheiro para aproveitar as coisas
                boas da vida.
              </p>

              <p
                className={styles.pFuncionalidades}
                style={{
                  color: '#1a7058',
                  fontWeight: 'bold',
                  fontSize: '25px',
                  textTransform: 'uppercase',
                }}
              >
                Já Imaginou Seu Sonho fora do Papel?
              </p>
            </div>
          </div>
          <div className={styles.child}>
            <Image
              // width="413"
              // height="400"
              src={sonhos}
              alt="sonhos-no-papel"
              // sizes="60vw"
              className={styles.sonhos}
              roundedCircle
            />
          </div>
        </div>
      </section>
    );
  }

  function getSessionPerguntasSIMNAO() {
    return (
      <section id="perguntas">
        <div style={{ textAlign: 'center' }}>
          <h3 className={styles.h3}>Me Diga...</h3>
          <p className={styles.pergunta}>
            Você já se enrolou com alguma dívida?
          </p>
          <div className={styles.divPerguntas}>
            <span className={styles.spanSim}>SIM</span> OU{' '}
            <span className={styles.spanNao}>NÂO</span>?
          </div>
          <br />
          <p className={styles.pergunta}>
            Todo mês sofre com a fatura do cartão?
          </p>
          <div className={styles.divPerguntas}>
            <span className={styles.spanSim}>SIM</span> OU{' '}
            <span className={styles.spanNao}>NÂO</span>?
          </div>
          <br />
          <p className={styles.pergunta}>
            O seu limite do cartão já faz parte do seu salário?
          </p>
          <div className={styles.divPerguntas}>
            <span className={styles.spanSim}>SIM</span> OU{' '}
            <span className={styles.spanNao}>NÂO</span>?
          </div>
          <br />
          <p className={styles.pergunta}>
            Não aguenta mais trabalhar tanto e nunca conseguir nada?
          </p>
          <div className={styles.divPerguntas}>
            <span className={styles.spanSim}>SIM</span> OU{' '}
            <span className={styles.spanNao}>NÂO</span>?
          </div>
          <br />
          <p className={styles.pergunta}>
            Todo mês você se pergunta para onde foi o seu dinheiro?
          </p>
          <div className={styles.divPerguntas}>
            <span className={styles.spanSim}>SIM</span> OU{' '}
            <span className={styles.spanNao}>NÂO</span>?
          </div>

          {getButton(
            'Se você respondeu SIM para pelo menos 2 perguntas, você precisa imediatamente deste material',
            'sessao-preco',
          )}
        </div>
      </section>
    );
  }

  function getSecionSafeBuy() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        <div>
          <Image
            src={cartoes}
            className={styles.imgCartoes}
            alt="formas-pagamento"
          />
        </div>
      </div>
    );
  }

  function getSectionTelas() {
    var arrayFuncionalidade = [
      'Video Aula com explicações de todas as funcionalidades do GUARDAR DINHEIRO PRO.',
      'Cadastre todas as suas Contas Correntes.',
      'Cadstro todos os seus Cartões de Crédito.',
      'Cadastre as suas Categoria de Gastos e Receitas.',
      'Orçamento - Crie um Planejamento de Gastos de acordo com sua necessidade. Planeje como utilizar sua receita.',
      'Orçamento - Crie um Planejamento de Gastos por Categoria.',
      'Cadastre suas Despesas, Receitas ou Investimentos',
      'Exemplo de Cadastro de Despesa.',
      'Extrato com todos os seus Lançamentos com Filtros Inteligentes.',
      'Resumo Mensal - Despesas, Receiras, Investimentos e Orçamento.',
      'Resumo Mensal - Despesas e Receiras por Categoria',
      'Resumo Mensal - Balanço do Mês por Conta.',
      'Resumo Mensal - Despesas por Cartão de Crédito e dias que você mais gastou.',
      'Resumo Anual - Total de Despesas, Receitas, Investimentos e Média de Saldos já Calculando Quanto você precisa na sua Reserva de Emergência.',
      'Resumo Anual - Visualização Mês a Mês das Despesas, Receitas e Investimentos.',
      'Patrimônio - Visualização de todos os seus Patrimônios por Tipo (Imóveis e Ativos Financeiros).',
      'Projetos e Metas - Controle a Evolução dos Seus Projetos e Metas.',
      'Dívidas - Acompanhe a eliminação das Suas Dividas.',
    ];
    var arrayImg = [];
    arrayImg = [
      aula,
      cadastroConta,
      cadastroCartoes,
      cadastroCategoria,
      orcamento,
      orcamento2,
      cadastro,
      cadastroDespesa,
      extrato,
      resumoMensal,
      resumoMensal2,
      resumoMensal3,
      resumoMensal4,
      resumoAnual,
      resumoAnual2,
      patrimonio,
      cadastroMetas,
      dividas,
    ];
    return (
      <>
        <div style={{ textAlign: 'center' }}>
          <h3 className={styles.h3} style={{ color: 'black' }}>
            Conheça as Principais Funcionalidades
          </h3>

          <Carousel>
            {arrayImg.map((img, idx) => {
              return (
                <Carousel.Item className={styles.carousel}>
                  <p className={styles.pFuncionalidades}>
                    {arrayFuncionalidade[idx]}
                  </p>

                  <Image
                    className={styles.telas}
                    src={img}
                    alt={'telas-' + idx.toString()}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </>
    );
  }

  // function getSectionBox() {
  //   return (
  //     <section id="box">
  //       <div className={styles.parent}>
  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Balanço Mensal com total de Despesa, Receita e Investimentos no
  //             Mês
  //           </p>
  //         </div>
  //         <div className={styles.child}>
  //           <Image src={boxBalancoMensal} alt="" className={styles.box} />
  //         </div>

  //         <div className={styles.child}>
  //           <Image src={boxOrcamento} alt="" className={styles.box} />
  //         </div>

  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Como está indo o seu planejamento financeiro no mês? Está indo
  //             conforme planejado?
  //           </p>
  //         </div>

  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Visualização das Despesas por Categoria. Aqui você saberá
  //             exatamente para onde seu dinheiro foi parar.
  //           </p>
  //         </div>
  //         <div className={styles.child}>
  //           <Image src={boxDespesaCategoria} alt="" />
  //         </div>

  //         <div className={styles.child}>
  //           <Image src={boxReceitaCategoria} alt="" />
  //         </div>
  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             De onde estão vindo suas receitas? Visualize por categoria as
  //             sduas fontes de renda.
  //           </p>
  //         </div>

  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Você tem controle das despesas suas contas correntes? Aqui você
  //             terá a visualização detalhada de cada conta.
  //           </p>
  //         </div>
  //         <div className={styles.child}>
  //           <Image src={boxDespesaConta} alt="" />
  //         </div>

  //         <div className={styles.child}>
  //           <Image src={boxReceitaConta} alt="" />
  //         </div>
  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Qual conta gera mais receita? Aqui você vai saber.
  //           </p>
  //         </div>

  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Você tem controle de seus cartões de crédito? Confira como está
  //             cada fatura de cartão de crédito.
  //           </p>
  //         </div>
  //         <div className={styles.child}>
  //           <Image src={boxDespesaCartao} alt="" />
  //         </div>

  //         <div className={styles.child}>
  //           <Image src={boxAlerta} alt="" />
  //         </div>
  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Quem aí nunca esqueceu de pagar aquela conta e depois teve que
  //             pagar juros?
  //           </p>
  //         </div>

  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Você tem controle quais são os dias que você mais gasta?
  //             Visualização de gastos dia a dia.
  //           </p>
  //         </div>
  //         <div className={styles.child}>
  //           <Image src={boxGastosDiario} alt="" />
  //         </div>

  //         <div className={styles.child}>
  //           <Image src={boxReserva} alt="" />
  //         </div>
  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Você sabe quanto você precisa ter de Reserva de Emergência? Agora,
  //             o sistema calcula automaticamente o necessário que você precisa
  //             ter.
  //           </p>
  //         </div>

  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Tenha uma visualização completa das suas depesas, receitas e
  //             investimentos no ano.
  //           </p>
  //         </div>
  //         <div className={styles.child}>
  //           <Image src={boxSaldosMes} alt="" />
  //         </div>
  //       </div>
  //     </section>
  //   );
  // }

  // function getSectionBoxMobile() {
  //   return (
  //     <section id="box">
  //       <div className={styles.parent}>
  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Balanço Mensal com total de Despesa, Receita e Investimentos no
  //             Mês
  //           </p>
  //         </div>
  //         <div className={styles.child}>
  //           <Image src={boxBalancoMensal} alt="" className={styles.box} />
  //         </div>

  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Como está indo o seu planejamento financeiro no mês? Está indo
  //             conforme planejado?
  //           </p>
  //         </div>

  //         <div className={styles.child}>
  //           <Image src={boxOrcamento} alt="" className={styles.box} />
  //         </div>

  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Visualização das Despesas por Categoria. Aqui você saberá
  //             exatamente para onde seu dinheiro foi parar.
  //           </p>
  //         </div>
  //         <div className={styles.child}>
  //           <Image src={boxDespesaCategoria} alt="" className={styles.box} />
  //         </div>

  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             De onde estão vindo suas receitas? Visualize por categoria as
  //             sduas fontes de renda.
  //           </p>
  //         </div>
  //         <div className={styles.child}>
  //           <Image src={boxReceitaCategoria} alt="" className={styles.box} />
  //         </div>

  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Você tem controle das despesas suas contas correntes? Aqui você
  //             terá a visualização detalhada de cada conta.
  //           </p>
  //         </div>
  //         <div className={styles.child}>
  //           <Image src={boxDespesaConta} alt="" className={styles.box} />
  //         </div>

  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Qual conta gera mais receita? Aqui você vai saber.
  //           </p>
  //         </div>

  //         <div className={styles.child}>
  //           <Image src={boxReceitaConta} alt="" className={styles.box} />
  //         </div>

  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Você tem controle de seus cartões de crédito? Confira como está
  //             cada fatura de cartão de crédito.
  //           </p>
  //         </div>
  //         <div className={styles.child}>
  //           <Image src={boxDespesaCartao} alt="" className={styles.box} />
  //         </div>

  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Quem aí nunca esqueceu de pagar aquela conta e depois teve que
  //             pagar juros?
  //           </p>
  //         </div>
  //         <div className={styles.child}>
  //           <Image src={boxAlerta} alt="" className={styles.box} />
  //         </div>

  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Você tem controle quais são os dias que você mais gasta?
  //             Visualização de gastos dia a dia.
  //           </p>
  //         </div>
  //         <div className={styles.child}>
  //           <Image src={boxGastosDiario} alt="" className={styles.box} />
  //         </div>

  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Você sabe quanto você precisa ter de Reserva de Emergência? Agora,
  //             o sistema calcula automaticamente o necessário que você precisa
  //             ter.
  //           </p>
  //         </div>
  //         <div className={styles.child}>
  //           <Image src={boxReserva} alt="" className={styles.box} />
  //         </div>

  //         <div className={styles.child}>
  //           <p className={styles.pFerramentas}>
  //             Tenha uma visualização completa das suas depesas, receitas e
  //             investimentos no ano.
  //           </p>
  //         </div>
  //         <div className={styles.child}>
  //           <Image src={boxSaldosMes} alt="" className={styles.box} />
  //         </div>
  //       </div>
  //     </section>
  //   );
  // }

  function getSectionDepoimentos() {
    return (
      <section id="depoimentos">
        <h3 className={styles.h3}>
          <span className={styles.span}>O que dizem nossos assinantes</span>
        </h3>
        <Carousel>
          <Carousel.Item className={styles.carousel}>
            <div className={styles.parentFerramentas}>
              <div className={styles.childDepoimentos}>
                {returnFiveStars()}
                <p className={styles.depoimento}>
                  <i>
                    <b>
                      "Eu não conseguia acompanhar minhas finanças pessoais
                      antes de usar o GUARDAR DINHEIRO PRO. Agora, com apenas
                      alguns cliques, tenho uma visão completa do meu patrimônio
                      líquido, receitas, despesas e investimentos. É uma
                      ferramenta poderosa que todos deveriam experimentar!"
                    </b>
                  </i>
                </p>
                <br />
                <p className={styles.depoimento}>
                  <b>"Carlos"</b>
                </p>
              </div>
              <div className={styles.childDepoimentos}>
                {returnFiveStars()}
                <p className={styles.depoimento}>
                  <i>
                    <b>
                      "Nunca pensei que um sistema de controle financeiro
                      pudesse fazer tanta diferença na minha vida. Agora consigo
                      visualizar meus gastos de forma clara e identificar onde
                      posso economizar. Estou muito mais consciente dos meus
                      hábitos financeiros e isso fez toda a diferença. Obrigado
                      GUARDAR DINHEIRO PRO!"
                    </b>
                  </i>
                </p>
                <br />
                <p className={styles.depoimento}>
                  <b>"Isabela"</b>
                </p>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item className={styles.carousel}>
            <div className={styles.parentFerramentas}>
              <div className={styles.childDepoimentos}>
                {returnFiveStars()}
                <p className={styles.depoimento}>
                  <i>
                    <b>
                      "Esse sistema é incrível! Desde que comecei a usar Guardar
                      Dinheiro PRO, minha vida mudou completamente. Agora
                      consigo gerenciar minhas finanças de forma eficiente e
                      planejar melhor meu futuro financeiro. Recomendo a todos"
                    </b>
                  </i>
                </p>
                <br />
                <p className={styles.depoimento}>
                  <b>"Tiago"</b>
                </p>
              </div>
              <div className={styles.childDepoimentos}>
                {returnFiveStars()}
                <p className={styles.depoimento}>
                  <i>
                    <b>
                      "GUARDAR DINHEIRO PRO simplificou minha vida financeira.
                      Agora tenho uma visão clara dos meus gastos, consigo
                      acompanhar meu orçamento mensal e até mesmo economizar
                      para investimentos futuros. Estou muito satisfeito!"
                    </b>
                  </i>
                </p>
                <br />
                <p className={styles.depoimento}>
                  <b>"Rita"</b>
                </p>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item className={styles.carousel}>
            <div className={styles.parentFerramentas}>
              <div className={styles.childDepoimentos}>
                {returnFiveStars()}
                <p className={styles.depoimento}>
                  <i>
                    <b>
                      "Nunca fui bom em lidar com finanças, mas o Guardar
                      Dinheiro PRO me ajudou a mudar isso. Com suas ferramentas
                      intuitivas e relatórios detalhados, consigo tomar decisões
                      mais inteligentes e evitar dívidas desnecessárias. É
                      incrível!"
                    </b>
                  </i>
                </p>
                <br />
                <p className={styles.depoimento}>
                  <b>"Rose"</b>
                </p>
              </div>
              <div className={styles.childDepoimentos}>
                {returnFiveStars()}
                <p className={styles.depoimento}>
                  <i>
                    <b>
                      "Eu costumava perder horas tentando organizar minhas
                      despesas e receitas, mas o Guaradr Dinheiro PRO fez tudo
                      isso por mim. Agora posso focar em outras coisas
                      importantes da minha vida, sabendo que meu dinheiro está
                      sendo cuidado de forma eficiente."
                    </b>
                  </i>
                </p>
                <br />
                <p className={styles.depoimento}>
                  <b>"Celso"</b>
                </p>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>
    );
  }

  function getSectionDuvidasFrequentes() {
    return (
      <section id="duvidas">
        <h3 className={styles.h3}>Dúvidas Frequentes:</h3>
        <Accordion defaultActiveKey="-1">
          <Accordion.Item eventKey="-1">
            <Accordion.Header className={styles.duvidas}>
              A assinatura do GUARDAR DINHEIRO PRO pode me ajudar a melhorar
              minha saúde financeira?
            </Accordion.Header>
            <Accordion.Body>
              Sim, a assinatura do GUARDAR DINHEIRO PRO foi criada para ajudá-lo
              a identificar onde reduzir seus gastos, e ter total controle das
              suas despesas, receitas e investimentos, melhorando sua saúde
              financeira em geral.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              A assinatura do GUARDAR DINHEIRO PRO é adequada para iniciantes em
              finanças e investimentos?
            </Accordion.Header>
            <Accordion.Body>
              Sim, a assinatura é projetada para ser fácil de usar e entender,
              sendo adequada tanto para iniciantes quanto para pessoas mais
              experientes em finanças pessoais e investimentos. Você também terá
              acesso às Video Aulas com as explicações de todas as
              funcionalidades da ferramenta.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>
              A assinatura do GUARDAR DINHEIRO PRO é compatível com dispositivos
              móveis e tablets?
            </Accordion.Header>
            <Accordion.Body>
              Você consegue acessar o site do GUARDAR DINHEIRO PRO de qualquer
              dispositivo que possui internet.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>
              A assinatura do GUARDAR DINHEIRO PRO oferece algum tipo de suporte
              ou atualizações?
            </Accordion.Header>
            <Accordion.Body>
              Sim, ao adquirir a assinatura do GUARDAR DINHEIRO PRO, você terá
              acesso a atualizações e melhorias sem precisar pagar mais nada por
              isso e em caso de dúvidas, você poderá entrar em contato
              diretamente com o suporte via whatsapp para tirar dúvidas.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>
              É seguro inserir minhas informações financeiras no Guardar
              Dinheiro PRO?
            </Accordion.Header>
            <Accordion.Body>
              Sim! Suas informações estão armazenadas em servidores na Nuvem e
              não são compartilhadas, garantindo toda segurança e privacidade
              dos seus dados.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>
              Há algum prazo de garantia caso eu não goste do GUARDAR DINHEIRO
              PRO?
            </Accordion.Header>
            <Accordion.Body>
              Sim, oferecemos uma garantia de satisfação de 7 dias.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Accordion.Header>
              Quando terei acesso ao GUARDAR DINHEIRO PRO?
            </Accordion.Header>
            <Accordion.Body>
              Para pagamentos feitos via cartão ou pix, o envio do seu login e
              senha para acesso é IMEDIATO! Para pagamentos via boleto bancário,
              o login e senha serão enviados em até 2 dias úteis devido a
              compensação bancária.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>
    );
  }

  function getSectionRecapitulando() {
    return (
      <section id="recapitulando">
        <div style={{ textAlign: 'center' }}>
          <h3 className={styles.h4}>Recapitulando tudo que você terá acesso</h3>

          <p className={styles.p}>
            Você terá acesso a um site que pode ser acessado do seu celular,
            computador ou tablet com todas as funcionalidades abaixo:
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Table className={styles.tableRecap}>
            <tbody>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Vídeo Aulas com o treinamento completo do
                  GUARDAR DINHEIRO PRO
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Despesas
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Receitas
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Investimentos
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Dívidas
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Contas
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Cartão de Crédito
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Despesa por Categoria
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Receita por Categoria
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Investimentos por Categoria
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Despesa por Conta
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Receita por Conta
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Planejamento Financeiro de Gastos
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Gastos Essenciais
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Gastos Não Essenciais
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Reserva de Emergência
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Limite de Gastos por Categoria
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Projetos e Metas
                </td>
              </tr>
              <tr>
                <td className={styles.tdRecap}>
                  {getSVG('check')} Controle de Patrimônio da Riqueza
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <br />
      </section>
    );
  }

  function getSessionPrecos() {
    return (
      <section id="precos">
        <div style={{ textAlign: 'center' }}>
          <h3 className={styles.h4}>Tudo isso Custaria:</h3>
          <span className={styles.priceCustaria}>R$470</span>
        </div>

        {/* <div style={{ textAlign: 'center' }}>
          <span className={styles.h4}>
            Mas sua vida financeira organizada com centavos por dia.{' '}
          </span>
        </div> */}
        <br />
        <div style={{ textAlign: 'center' }}>
          <h4 className={styles.h4}>Mas hoje você leva por apenas:</h4>
          <br />
          <br />
          {getSessionPrecos2()}
          {/* <span className={styles.spanPreco}>
            12 X de <span className={styles.priceNormal}>R$9,03</span>
          </span>

          <br />
          <br />
          <div>
            <span className={styles.spanPreco}>
              ou <span className={styles.priceNormal}>R$89,90</span> á vista
            </span>
          </div>
          <br />

          <div>
            <span className={styles.assinaturaAnual}>Assinatura Anual</span>
          </div>*/}
          <br />
          <br />

          {getSecionSafeBuy()}
          <br />
          <span className={styles.pergunta}>E se eu não gostar?</span>
          <br />
          <br />
          <span className={styles.pergunta}>O Risco é todo NOSSO!</span>
          <br />
          <br />
          <span className={styles.pergunta}>
            Se não gostar, você têm a Garantia incondicional de 7 DIAS!
          </span>
          {/* Sem letras miúdas! */}
          {/* <span className={styles.pergunta}>7 Dias de Garantia</span> */}
          <br />
          <Image src={garantia} alt="garantia" sizes="60vw" />
          {/*   */}
        </div>
      </section>
    );
  }

  function getSessionPrecos2() {
    return (
      <section id="precos2">
        <div className={styles.parentPreco}>
          <div className={[styles.package, styles.brilliant].join(' ')}>
            <div className={styles.name}>Anual</div>
            <div className={styles.price}>Mais Vendido</div>
            <div className={styles.trial}>Assinatura Anual</div>
            <hr />
            <ul>
              {/* <li>
                <strong className={styles.pagamento}>À Vista</strong>
              </li> */}
              <li className={styles.priceNormal}>R$ 49,90</li>
              <li className={styles.priceDia}>R$ 0,13 centavos por dia</li>
              {/* <hr /> */}
              {/* <li>
                <strong className={styles.pagamento}>Parcelado</strong>
              </li> */}
              {/* <li className={styles.priceNormal}>R$ 79,90</li> */}
              {/* <li className={styles.priceDia}>R$ 0,22 centavos por dia</li> */}

              {/* <li className={styles.priceNormal}>7 X de R$ 8,01</li>
              <li className={styles.priceDia}>R$ 0,15 centavos por dia</li> */}
              {/* <li className={styles.priceNormal}>7 X de R$ 8,01</li> */}
              <li className={styles.priceDia}>Economia de 40%</li>
              {/* <hr /> */}
            </ul>
            <div className={styles.divBtnComprar}>
              <button
                id="btn-plano-anual"
                className={styles.btnComprarPlano}
                onClick={() => {
                  window.gtag('event', 'gdpro_checkout_anual');
                  window.open(
                    'https://app.monetizze.com.br/checkout/DPE233437',
                    '_self',
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-cart-check-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />
                </svg>{' '}
                QUERO O ACESSO IMEDIATO
              </button>
            </div>
          </div>
          <div className={styles.package}>
            <div className={styles.name}>Mensal</div>
            <br />
            <div className={styles.trial}>Assinatura Mensal</div>
            <hr />
            <ul>
              {/* <li>
                <strong className={styles.pagamento}>Á Vista</strong>
              </li> */}
              <li className={styles.priceNormal}>R$ 6,90</li>
              <li className={styles.priceDia}>R$ 0,23 centavos por dia</li>
              {/* <hr /> */}
            </ul>
            <div className={styles.divBtnComprar}>
              <button
                id="btn-plano-mensal"
                className={styles.btnComprarPlano}
                onClick={() => {
                  window.gtag('event', 'gdpro_checkout_mensal');
                  window.open(
                    'https://app.monetizze.com.br/checkout/DLP234314',
                    '_self',
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-cart-check-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />
                </svg>{' '}
                QUERO O ACESSO IMEDIATO
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }

  function getSectionAtualizacoes() {
    return (
      <div>
        <h4 className={styles.h4}>Os benefícios não acabam por aqui</h4>
        <p className={styles.p}>
          Estamos evoluindo nosso sistema diariamente, e com isso quem ganha é
          você.
        </p>
        <p className={styles.p}>
          Você terá acesso a todas as melhorias e atualizações que serão feitas
          durante a sua assinatura.
        </p>
      </div>
    );
  }

  function getAindaTemDuvidas() {
    return (
      <div>
        <h3 className={styles.h3}>VOCÊ AINDA TEM ALGUMA DÚVIDA?</h3>
        {getButton('FALE COM NOSSO SUPORTE', 'suporte')}
      </div>
    );
  }

  function getTimeLine() {
    return (
      <>
        <div className={styles.timeline}>
          <div className={styles.timelineWrapper}>
            <h3 className={styles.h4} style={{ color: 'white' }}>
              TUDO O QUE VOCÊ PRECISA PARA ORGANIZAR SEU DINHEIRO EM UM SÓ
              LUGAR.{' '}
            </h3>
            <br />
            <h4 className={styles.h4} style={{ color: 'white' }}>
              Como o GUARDAR DINHEIRO PRO vai te ajudar?{' '}
              <FontAwesomeIcon
                style={{ fontSize: '30px' }}
                icon={faHandHoldingUsd}
              />
            </h4>
            <ul className={styles.timelineSession}>
              <li>
                <div className={styles.timelineDiv}>ESQUEÇA PLANILHAS</div>
                <p className={styles.timelineP}>
                  Registre suas contas de forma prática no site GUARDAR DINHEIRO
                  PRO, conseguindo assim ter uma visão completa da suas finanças
                  em um só lugar, na palma da sua mão.
                </p>
              </li>

              <li>
                <div className={styles.timelineDiv}>
                  Controle gastos, receitas e investimentos
                </div>
                <p className={styles.timelineP}>
                  Saiba de uma vez por todas pra onde vai cada centavo do seu
                  dinheiro. Controle de todas as suas contas e cartões de
                  crédito. Nunca mais estoure o limite dos seus cartões de
                  crédito.
                </p>
              </li>

              <li>
                <div className={styles.timelineDiv}>
                  Receba Alertas automáticos para te ajudar no dia a dia
                  financeiro
                </div>
                <p className={styles.timelineP}>
                  Nunca mais deixe de pagar uma conta que esqueceu, nunca mais
                  estoure o limite do cartão, nunca mais gaste sem controle em
                  algo desnecessário, nunca mais esqueça de investir e muito
                  mais.
                </p>
                <Alert
                  variant="danger"
                  style={{ paddingTop: '1px', paddingBottom: '1px' }}
                >
                  <p
                    className={styles.pAlertasAnimate}
                    style={{ fontWeight: 'bold' }}
                  >
                    Você possui lançamentos vencidos neste mês. Acesse seu
                    Extrato para efetuar o pagamento!!!
                  </p>
                  <br />
                  <p className={styles.pAlertasAnimate}>
                    O Limite do cartão Itaú está quase sendo atingido. Limite
                    disponível: R$ 126,00!!!
                  </p>
                  <br />
                  <p className={styles.pAlertasAnimate}>
                    Você ultrapassou seu limite de gastos em Supermercado!!!
                  </p>
                </Alert>

                <Alert
                  variant="success"
                  style={{ paddingTop: '1px', paddingBottom: '1px' }}
                >
                  <p
                    className={styles.pAlertasAnimateBom}
                    style={{ fontWeight: 'bold' }}
                  >
                    Tem dinheiro Sobrando este mês. Vamos Investir?
                  </p>
                  <br />
                  <p
                    className={styles.pAlertasAnimateBom}
                    style={{ fontWeight: 'bold' }}
                  >
                    Parabéns, este mês você atingiu seu objetivo financeiro!!!
                  </p>
                </Alert>
              </li>

              <li>
                <div className={styles.timelineDiv}>Elimine suas dívidas</div>
                <p className={styles.timelineP}>
                  Com o Guardar Dinheiro PRO, você vai conseguir identificar
                  gastos desnecessários e criar estratégias para reduzir dívidas
                  e evitar endividamentos futuros.
                </p>
              </li>
              <li>
                <div className={styles.timelineDiv}>
                  Acompanhe seu planejamento financeiro
                </div>
                <p className={styles.timelineP}>
                  Visão detalhada do planejamento com notificações e alertas dos
                  seus gastos exagerados.
                </p>
              </li>
              <li>
                <div className={styles.timelineDiv}>
                  Controle a Evolução dos seus Projetos e Metas de Riqueza
                </div>
                <p className={styles.timelineP}>
                  Coloque suas metas e sonhos fora da caixa e automatize um
                  plano para alcançar seus objetivos.
                </p>
              </li>
              <li>
                <div className={styles.timelineDiv}>
                  Video Aulas - GUARDAR DINHEIRO PRO
                </div>
                <p className={styles.timelineP}>
                  Você terá acesso a um treinamento completo do GUARDAR DINHEIRO
                  PRO, com explicações de todas as funcionalidades disponíveis
                  no sistema.
                </p>
              </li>
              <li>
                <div className={styles.timelineDiv}>
                  Um planejador financeiro disponível por WhatsApp pra você
                  tirar QUALQUER dúvida
                </div>
                <p className={styles.timelineP}>
                  Tenha um planejador financeiro para tirar todas as suas
                  dúvidas sobre o sistema.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }

  // function getClarity() {
  //   return (
  //     <script type="text/javascript">
  //       {`
  //           (function (c, l, a, r, i, t, y) {
  //             c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
  //             t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
  //             y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
  //           })(window, document, "clarity", "script", "i4gwuzgspl");
  //       `}
  //     </script>
  //   );
  // }

  return (
    <>
      <Helmet>
        <title>{'GUARDAR DINHEIRO PRO - Assinatura'}</title>
        <meta
          itemProp="name"
          content="GUARDAR DINHEIRO PRO - Assinatura "
        ></meta>
        <meta
          name="description"
          content="O Site para organizar seu dinheiro e fazer o seu dinheiro render todo mês. Gerencie seus gastos de forma automática e veja seu saldo positivo no final do Mês."
        />

        <link
          rel="canonical"
          href={'https://www.guardardinheiro.com.br/lp/guardar-dinheiro-pro'}
        ></link>
        <link
          rel="shortlink"
          href={
            'https://www.guardardinheiro.com.br/lp/guardar-dinheiro-pro/?p=9013'
          }
        ></link>

        <script type="application/ld+json">
          {`{
          "@context":"https://schema.org",
          "@type":"Organization",
          "url":"https://www.guardardinheiro.com.br",
          "name":"Guardar Dinheiro",
          "logo": "https://blogguardardinheiro.s3.sa-east-1.amazonaws.com/logo3.png",
          "description": "O Site que reuniu várias ferramentas para você sair das dívidas e conseguir fazer o seu dinheiro render todo mês. Gerencie seus gastos, economize tempo e alcance seus objetivos financeiros com facilidade. Assine Já!",
          "sameAs" : [
            "https://www.facebook.com/siteguardardinhero",
            "https://twitter.com/guardardinheir1",
            "https://www.linkedin.com/in/guardar-dinheiro-267073230/",
            "https://br.pinterest.com/guardardinheiro/",
            "https://www.instagram.com/guardardinheiro/",
            "https://www.youtube.com/channel/CYhHakmdv-lGolh3zahffCQ",    
            "https://medium.com/@guardardinheiro"
          ]            
        }`}
        </script>

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-2K23FV25Q6"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'G-2K23FV25Q6');        
            gtag('event','page_view_pro')
        `}
        </script>

        {/* {getClarity()} */}
      </Helmet>

      <style type="text/css">
        {`
          .carousel-control-prev-icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-left-square' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z'/%3E%3C/svg%3E") !important;
           }

           .carousel-control-next-icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-arrow-right-square' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm4.5 5.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z'/%3E%3C/svg%3E") !important;
          }

          .carousel-indicators [data-bs-target] {
            background-color: #28a745;
          }

          .accordion-button{
            font-family: 'Roboto', sans-serif;
            font-weight: bold;
            font-size: 18px;
          }
           `}
      </style>

      {getSectionQtdeFerramentas()}

      <hr />

      {getButton('QUERO SABER MAIS', 'sessao-youtube')}
      <br />
      <br />
      <Container>
        <h2 className={styles.h2} id="video-youtube">
          ASSISTA AGORA A AULA ABAIXO E VEJA COMO O{' '}
          <span style={{ color: '#1a7058', fontWeight: 'bold' }}>
            GUARDAR DINHEIRO PRO
          </span>{' '}
          VAI TE AJUDAR.
        </h2>

        <div
          // id="video-youtube"
          className="video"
          style={{
            position: 'relative',
            paddingBottom: '56.25%' /* 16:9 */,
            paddingTop: 25,
            height: 0,
            margin: '25px',
          }}
        >
          <iframe
            title="youTubeVideoPro"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            // src={`https://www.youtube.com/embed/JQ79DkvVL0c`}
            // src={`https://www.youtube.com/embed/JQ79DkvVL0c?autoplay=1`}
            src="https://www.youtube.com/embed/JQ79DkvVL0c?autoplay=1&mute=1"
          />
        </div>
        {getButton('SIM! EU QUERO O GUARDAR DINHEIRO PRO', 'sessao-preco')}

        {getTimeLine()}
        {getButton('SIM! EU QUERO O GUARDAR DINHEIRO PRO', 'sessao-preco')}

        <hr />
        {getSectionSonho()}
        <hr />

        <br />
        {getSectionDepoimentos()}
        <br />
        <hr />
        {getSectionRecapitulando()}
        <br />
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Image
            src={mockup3d}
            className={styles.box}
            alt="telas guardar dinheiro pro"
          />
        </div>
        <br />
        {getSessionPrecos()}
        <hr />
        <br />
        {getSectionAtualizacoes()}
        <hr />
        <br />
        {getButton(
          'SIM! EU QUERO TER O CONTROLE DA MINHA VIDA FINANCEIRA',
          'sessao-preco',
        )}
        <br />
        {getSectionDuvidasFrequentes()}
        <br />
        <br />
        {getAindaTemDuvidas()}
        <br />
        <br />
      </Container>
      <Footer isLP={true} />
      <Whatsapp pagina="guardar-dinheiro-pro" />
    </>
  );
};

export default LpControleFinanceiro;
