import React from 'react';
import firebase from '../Firebase/firebaseConnection';
import { useNavigate } from 'react-router-dom';
// import { openInNewTab } from '../Components/Helper/Tabs'

export const UserContext = React.createContext();

export const UserStorage = ({ children }) => {
  const [emailsAdm, setEmailAdms] = React.useState([
    'siteguardardinheiro@gmail.com',
    'douglas.eduardof@bol.com.br',
    'gracy.mariano@gmail.com',
  ]);
  const [login, setLogin] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [user, setUser] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [isAssinante, setIsAssinante] = React.useState(false);
  const navigate = useNavigate();

  const userLogout = React.useCallback(
    async function logout() {
      await firebase.auth().signOut();

      window.localStorage.removeItem('gd_uid');
      window.localStorage.removeItem('gd_user');
      window.sessionStorage.removeItem('gd_pro');
      setLogin(false);
      navigate('/autenticacao');
    },
    [navigate],
  );

  async function userLogin(email, senha) {
    try {
      setError(null);
      setLoading(true);
      await firebase
        .auth()
        .signInWithEmailAndPassword(email, senha)
        .then(async (value) => {
          await firebase
            .firestore()
            .collection('users')
            .doc(value.user.uid)
            .get()
            .then((snapshot) => {
              window.localStorage.setItem('gd_uid', value.user.uid);
              window.localStorage.setItem('gd_user', snapshot.data().nome);
              window.localStorage.setItem('gd_mail', snapshot.data().email);

              setUser(snapshot.data().nome);

              setLogin(true);
              getAssinantePro(snapshot.data().email, false, false);
            });
        });
    } catch (err) {
      console.log(err.code);
      if (err.code === 'auth/user-not-found') {
        setError('E-mail não cadastrado');
      } else if (err.code === 'auth/wrong-password') {
        setError('Senha Inválida');
      } else {
        setError(err.message);
      }
      setLogin(false);
    } finally {
      setLoading(false);
    }
  }

  function addTelegramWhatsapp() {
    var d = new Date();
    var hora = d.getHours();
    if (hora % 3 === 0) {
      window.open('https://t.me/+6JN2oQW-M5BlMTlh', '_blank');
      // window.open('https://t.me/+I-RJXHMAcJQwOGFh', '_blank');
    } else {
      window.open(
        'https://blog.guardardinheiro.com.br/convite-whatsapp/',
        '_blank',
      );
    }
  }

  async function novoUsuario(nome, email, senha, telefone) {
    await firebase
      .auth()
      .createUserWithEmailAndPassword(email, senha)
      .then(async (value) => {
        await firebase
          .firestore()
          .collection('users')
          .doc(value.user.uid)
          .set({
            nome: nome,
            telefone: telefone,
            email: email,
          })
          .then(() => {
            window.localStorage.setItem('gd_uid', value.user.uid);
            window.localStorage.setItem('gd_user', nome);
            window.localStorage.setItem('gd_mail', email);
            setError(null);
            setUser(nome);
            setLogin(true);
            getAssinantePro(email, true, false);
            window.gtag('event', 'cadastro');
          });
      })
      .catch((error) => {
        if (error.code === 'auth/weak-password') {
          setError(
            'Senha muito fraca. A senha deve conter no Mínimo 6 caracteres',
          );
        } else if (error.code === 'auth/email-already-in-use') {
          setError('Esse email já existe!');
        }
      });
  }

  async function esqueceuSenha(email) {
    await firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        alert(
          'Altere a senha clicando no link que você recebeu no seu e-mail ' +
            email,
        );
      })
      .catch(function (error) {
        if (error.code === 'auth/invalid-email') {
          setError('E-mail inválido');
        } else if (error.code === 'auth/user-not-found') {
          setError('E-mail não cadastrado');
        } else {
          console.log(error);
          setError(error);
        }
      });
  }

  async function getMonetizzeTempToken() {
    const url = 'https://api.monetizze.com.br/2.1/token';
    const headers = {
      X_CONSUMER_KEY: 'KSaKECsWLOv6izjvkPt23EFlQvpcuPjl',
    };

    const response = await fetch(url, { headers });
    const data = await response.json();
    // console.log(data);
    return data.token;
  }

  async function getAssinantePro(email, isNovoUsuario, isAutoLogin) {
    window.sessionStorage.setItem('gd_pro', '0');
    setIsAssinante(false);
    try {
      const token = await getMonetizzeTempToken();

      const url = 'https://api.monetizze.com.br/2.1/transactions';
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        TOKEN: token,
      };
      const data = {
        product: '251065',
        email: email,
      };

      const params = new URLSearchParams(data).toString();
      const requestUrl = `${url}?${params}`;

      const response = await fetch(requestUrl, { headers });

      const resposta = await response.json();
      // console.log(resposta.dados);
      // console.log(resposta.dados.length);

      if (resposta.dados.length > 0) {
        console.log(resposta.dados[0]['assinatura']['status']);
        window.sessionStorage.setItem('gd_pro', '1');

        var retornoMonetizze = resposta.dados[0]['assinatura']['status'];

        for (var i = 0; i < resposta.dados.length; ++i) {
          console.log(resposta.dados[i]['assinatura']['status']);
          if (resposta.dados[i]['assinatura']['status'] === 'Ativa') {
            retornoMonetizze = 'Ativa';
            console.log('tem 1 ativa');
          }
        }

        if (retornoMonetizze === 'Ativa') {
          setIsAssinante(true);
          console.log(resposta.dados[0]['assinatura']['status']);

          window.open('/plano-pro/home', '_self');
        } else {
          if (emailsAdm.includes(email)) {
            window.sessionStorage.setItem('gd_pro', '1');
            setIsAssinante(true);
            window.open('/plano-pro/home', '_self');
          }
          navigate('/dashboard');
          if (isNovoUsuario) {
            addTelegramWhatsapp();
          }
        }
      } else {
        navigate('/dashboard');
        if (isNovoUsuario) {
          addTelegramWhatsapp();
        }
      }
    } catch (err) {
      console.log(err.code);
      window.gtag('event', 'erro_monetizze');
    }
  }

  async function getAssinanteProAutoLogin(email) {
    window.sessionStorage.setItem('gd_pro', '0');
    setIsAssinante(false);
    try {
      const token = await getMonetizzeTempToken();

      const url = 'https://api.monetizze.com.br/2.1/transactions';
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        TOKEN: token,
      };
      // console.log(email);
      const data = {
        product: '251065',
        email: email,
      };

      const params = new URLSearchParams(data).toString();
      const requestUrl = `${url}?${params}`;

      const response = await fetch(requestUrl, { headers });

      const resposta = await response.json();

      if (resposta.dados.length > 0) {
        // console.log(resposta.dados);
        window.sessionStorage.setItem('gd_pro', '1');

        var retornoMonetizze = resposta.dados[0]['assinatura']['status'];
        if (retornoMonetizze === 'Ativa') {
          setIsAssinante(true);
        } else {
          if (emailsAdm.includes(email)) {
            window.sessionStorage.setItem('gd_pro', '1');
            setIsAssinante(true);
          } else {
            setLogin(false);
            navigate('/autenticacao');
          }
        }
      } else {
        if (emailsAdm.includes(email)) {
          window.sessionStorage.setItem('gd_pro', '1');
          setIsAssinante(true);
        } else {
          setLogin(false);
          // navigate('/lp/guardar-dinheiro-pro');
          window.open('/lp/guardar-dinheiro-pro', '_self');
        }
      }
    } catch (err) {
      console.log(err.code);
      window.gtag('event', 'erro_monetizze');
    }
  }

  React.useEffect(() => {
    async function autoLogin() {
      const token = window.localStorage.getItem('gd_uid');
      const emailLocal = window.localStorage.getItem('gd_mail');

      if (token && emailLocal) {
        try {
          setLogin(true);
          setError(null);
          setLoading(true);
          setUser(window.localStorage.getItem('gd_user'));
          setEmail(window.localStorage.getItem('gd_mail'));

          if (window.location.href.includes('plano-pro')) {
            // console.log(window.sessionStorage.getItem('gd_pro'));
            if (window.sessionStorage.getItem('gd_pro') !== 1) {
              getAssinanteProAutoLogin(emailLocal);
            }
          }
        } catch (err) {
          userLogout();
        } finally {
          setLoading(false);
        }
      } else {
        setLogin(false);
        if (
          window.location.href.includes('plano-pro') ||
          window.location.href.includes('dashboard')
        ) {
          navigate('/autenticacao');
        }
      }
    }
    autoLogin();
  }, [userLogout, navigate]);

  return (
    <UserContext.Provider
      value={{
        novoUsuario,
        userLogin,
        userLogout,
        esqueceuSenha,
        user,
        error,
        loading,
        login,
        email,
        getAssinantePro,
        isAssinante,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
