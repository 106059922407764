import { useParams } from 'react-router-dom';
import React from 'react';
import { Container } from 'react-bootstrap';
import stylePost from '../../Components/Tags/Post.module.css';
import isMobile from '../../Components/Helper/Mobile';
import BreadcumbH1 from '../../Components/Tags/BreadcumbH1';
import Paragrafo from '../../Components/Tags/Paragrafo';
import H2 from '../../Components/Tags/H2';
// import RatingStars from '../../Components/Rating/RatingStars';
import Categorias from '../../Components/Categorias/Categorias';
import AdsContent1 from '../../Components/AdsJoinAds/AdsContent1';
import stylesButton from '../../Pages/_css/Button.module.css';
import stylesTipografia from '../../Pages/_css/Tipografia.module.css';
import stylesLista from '../../Components/Tags/Lista.module.css';
import ItemDuvida from '../../Components/Tags/ItemDuvida';
import AdsContent2 from '../../Components/AdsJoinAds/AdsContent2';
import Head from '../../Components/Tags/Head';

const QuantoRendeMilPouso = () => {
  const params = useParams();
  const mobile = isMobile();
  const [produto, setProduto] = React.useState(null);

  React.useEffect(() => {
    // retornaResultados(params.valor, 12);

    getNomeProduto();
  }, []);

  function getNomeProduto() {
    if (params.produto === 'poupanca') {
      setProduto('Poupança');
    } else if (params.produto === 'mercadopago') {
      setProduto('Mercado Pago');
    } else if (params.produto === 'nubank') {
      setProduto('Nubank');
    } else if (params.produto === 'caixinha-nubank') {
      setProduto('Caixinha Nubank');
    } else if (params.produto === 'picpay') {
      setProduto('PicPay');
    } else if (params.produto === 'pagbank') {
      setProduto('PagBank');
    } else if (params.produto === 'tesouro') {
      setProduto('Tesouro Selic');
    } else if (params.produto === 'selic') {
      setProduto('Selic');
    } else if (params.produto === 'next') {
      setProduto('Banco Next');
    } else if (params.produto === 'inter') {
      setProduto('Banco Inter');
    } else if (params.produto === '99pay') {
      setProduto('99Pay');
    } else if (params.produto === 'neon') {
      setProduto('Neon');
    } else if (params.produto === 'sofisa') {
      setProduto('Banco Sofisa');
    } else if (params.produto === 'iti') {
      setProduto('Iti');
    } else if (params.produto === 'will') {
      setProduto('Will Bank');
    } else if (params.produto === 'bv') {
      setProduto('Banco BV');
    } else if (params.produto === 'renda-fixa') {
      setProduto('Renda Fixa');
    } else if (params.produto === 'cdb') {
      setProduto('CDB');
    } else if (params.produto === 'banco-digital') {
      setProduto('Banco Digital');
    } else {
      setProduto('Poupança');
    }
  }

  function getTitle() {
    let titulo = '';

    if (params.valor > 0) {
      titulo = 'Quanto Rende ' + params.valor + ' Mil Reais ';
    } else {
      titulo = 'Quanto Rende Seu Dinheiro ';
    }

    if (params.produto === 'poupanca') {
      titulo = titulo + 'na Poupança';
    } else if (params.produto === 'mercadopago') {
      titulo = titulo + 'no Mercado Pago';
    } else if (params.produto === 'nubank') {
      titulo = titulo + 'no Nubank';
    } else if (params.produto === 'caixinha-nubank') {
      titulo = titulo + 'na Caixinha Nubank';
    } else if (params.produto === 'picpay') {
      titulo = titulo + 'no PicPay';
    } else if (params.produto === 'pagbank') {
      titulo = titulo + 'no PagBank';
    } else if (params.produto === 'next') {
      titulo = titulo + 'no Banco Next';
    } else if (params.produto === 'inter') {
      titulo = titulo + 'no Banco Inter';
    } else if (params.produto === '99pay') {
      titulo = titulo + 'na 99Pay';
    } else if (params.produto === 'neon') {
      titulo = titulo + 'no Banco Neon';
    } else if (params.produto === 'sofisa') {
      titulo = titulo + 'no Banco Sofisa';
    } else if (params.produto === 'iti') {
      titulo = titulo + 'no Iti';
    } else if (params.produto === 'will') {
      titulo = titulo + 'no Will Bank';
    } else if (params.produto === 'bv') {
      titulo = titulo + 'no Banco BV';
    } else if (params.produto === 'tesouro') {
      titulo = titulo + 'no Tesouro Selic';
    } else if (params.produto === 'selic') {
      titulo = titulo + 'na Selic';
    } else if (params.produto === 'renda-fixa') {
      titulo = titulo + 'em Renda Fixa';
    } else if (params.produto === 'cdb') {
      titulo = titulo + 'em CDB';
    } else if (params.produto === 'banco-digital') {
      titulo = titulo + 'em Banco Digital';
    } else {
      titulo = titulo + 'na Poupança';
    }

    return titulo;
  }

  function retornaShemaOrg() {
    var endereco =
      'https://www.guardardinheiro.com.br/quanto-rende/' +
      params.valor +
      '/mil/' +
      params.produto;

    return (
      <script type="application/ld+json">
        {`{
            "@context":"https://schema.org",
            "@type":"WebPage",
            "@id": "${endereco}#webpage",
            "url":"${endereco}",
            "name":"${getTitle()}",
            "isPartOf":
              {
                "@id":"https://www.guardardinheiro.com.br/#website"
                },
            "description":"${getTitle()}",
            "inLanguage":"pt-BR"
          }`}
      </script>
    );
  }

  function retornaSchemaBreadcrumbList() {
    var endereco =
      'https://www.guardardinheiro.com.br/quanto-rende/' +
      params.valor +
      '/mil/' +
      params.produto;

    return (
      <script type="application/ld+json">
        {`{
          "@context":"https://schema.org",
          "@type":"BreadcrumbList",
          "itemListElement":
            [
              {
                "@type":"ListItem",
                "position":1,
                "name":"Guardar Dinheiro",
                "item":"https://www.guardardinheiro.com.br"
              },
              {
                "@type":"ListItem",
                "position":2,
                "name":"${getTitle()}",
                "item":"${endereco}"
              }              
            ]
        }`}
      </script>
    );
  }

  function getButtonSimulador() {
    return (
      <a
        href={
          '/quanto-rende/' +
          params.valor +
          '/mil/' +
          params.produto +
          '/simulador'
        }
        className={stylesButton.a}
      >
        ACESSAR SIMULADOR
      </a>
    );
  }

  function retornaPicPay() {
    return (
      <>
        <p className={stylesTipografia.paragrafo}>
          No mundo financeiro atual, os investimentos digitais estão se tornando
          cada vez mais populares. Com a crescente adoção de tecnologias
          financeiras, como aplicativos de pagamento e carteiras virtuais, surge
          uma questão frequente: quanto rende PicPay?
        </p>
        <p className={stylesTipografia.paragrafo}>
          Neste artigo, vamos apresentar algumas oportunidades de investimento
          oferecidas por essa plataforma e desvendando os segredos por trás do
          rendimento oferecido aos usuários.
        </p>
        <h2 className={stylesTipografia.h2}>O Que é o PicPay?</h2>
        <p className={stylesTipografia.paragrafo}>
          {'Antes de mergulharmos nas questões de quanto rende ' +
            params.valor +
            ' mil reais ' +
            produto +
            ', é importante entender o que é o PicPay. Em poucas palavras, o PicPay é um aplicativo de pagamento digital que permite aos usuários realizarem transações financeiras de forma rápida e segura.'}
        </p>
        <p className={stylesTipografia.paragrafo}>
          Além disso, o PicPay oferece uma variedade de serviços, incluindo
          recarga de celular, pagamento de contas, compra de créditos para
          transporte e, é claro, opções de investimento.
        </p>
        <h2 className={stylesTipografia.h2}>
          Opções de Investimento no PicPay
        </h2>
        <p className={stylesTipografia.paragrafo}>
          Uma das características mais atrativas do PicPay é a possibilidade de
          investir o saldo disponível na plataforma e obter rendimentos.
          Atualmente, o PicPay oferece estas duas opções principais de
          investimento sem riscos: o "CDB PicPay" e o "Poupança PicPay". Vamos
          explorar cada uma delas em detalhes.
        </p>
        <h3 className={stylesTipografia.h3}>1. CDB PicPay</h3>
        <AdsContent2 />
        <p className={stylesTipografia.paragrafo}>
          O CDB (Certificado de Depósito Bancário) oferecido pelo PicPay é uma
          opção de investimento que permite aos usuários aplicarem seu saldo e
          obterem rendimentos a uma taxa fixa. Essa taxa é definida pelo próprio
          PicPay e pode variar de acordo com o período de investimento escolhido
          pelo usuário. Geralmente, quanto maior o prazo de investimento, maior
          será a taxa de rendimento oferecida.
        </p>
        <p className={stylesTipografia.paragrafo}>
          {'Para saber quanto rende ' +
            params.valor +
            ' mil no CDB do ' +
            produto +
            ', acesso nosso Simulador.'}
        </p>
        {getButtonSimulador()}
        <br />
        <h3 className={stylesTipografia.h3}>2. Poupança PicPay:</h3>
        <p className={stylesTipografia.paragrafo}>
          A Poupança PicPay é outra opção de investimento disponível na
          plataforma. Nesse caso, os usuários podem aplicar seu saldo na
          poupança oferecida pelo PicPay e obter rendimentos de acordo com a
          taxa de remuneração definida pelo banco parceiro da plataforma. Assim
          como no CDB, a taxa de rendimento da Poupança PicPay pode variar de
          acordo com o período de investimento.
        </p>
        <p className={stylesTipografia.paragrafo}>
          {'Para saber quanto rende ' +
            params.valor +
            ' mil na Poupança do ' +
            produto +
            ', também é possível conferir em acesso nosso Simulador.'}
        </p>
        {getButtonSimulador()}
        <br />
        <h2 className={stylesTipografia.h2}>
          É seguro investir no CDB PicPay ou Poupança PicPay?
        </h2>
        <p className={stylesTipografia.paragrafo}>
          Sim! Seu dinheiro no PicPay conta com a garantia do{' '}
          <a
            href="https://blog.guardardinheiro.com.br/o-que-e-fgc/"
            target="_blank"
          >
            {' '}
            Fundo Garantidor de Créditos (FGC)
          </a>{' '}
          de até R$ 250 mil por CPF, trazendo mais segurança para investir.
        </p>
        <h2 className={stylesTipografia.h2}>Como funciona o FGC no PicPay?</h2>
        <p className={stylesTipografia.paragrafo}>
          O Fundo Garantidor de Créditos (FGC) é uma entidade privada, sem fins
          lucrativos, que foi criada com o objetivo de proteger os depositantes
          e investidores em caso de insolvência ou liquidação extrajudicial de
          instituições financeiras autorizadas pelo Banco Central do Brasil
          (BCB).
        </p>
        <p className={stylesTipografia.paragrafo}>
          O FGC atua como um mecanismo de garantia, oferecendo proteção aos
          recursos dos depositantes e investidores em determinadas situações
          adversas. Por exemplo, se você tiver até R$ 250.000,00 investido no
          PicPay e o PicPay falir, o FGC devolve o seu dinheiro investido.
        </p>
        <p className={stylesTipografia.paragrafo}>
          É importante ressaltar que nem todos os tipos de depósitos e
          investimentos são cobertos pelo FGC. Geralmente, são garantidos pelo
          fundo os depósitos à vista ou sacáveis mediante aviso prévio,
          depósitos de poupança, depósitos a prazo, letras de câmbio e letras
          imobiliárias. Investimentos em fundos de investimento, ações,
          debêntures e outros títulos de renda variável não são cobertos pelo
          FGC.
        </p>
        <h2 className={stylesTipografia.h2}>Como funciona o CDB do PicPay?</h2>
        <p className={stylesTipografia.paragrafo}>
          O Certificado de Depósito Bancário (CDB) é um título de renda fixa
          emitido por instituições financeiras, como bancos comerciais, com o
          objetivo de captar recursos para financiar suas atividades.
        </p>

        <p className={stylesTipografia.paragrafo}>
          Funciona basicamente como um empréstimo feito pelo investidor ao banco
          em troca de uma remuneração, que pode ser pré ou pós-fixada,
          dependendo das condições acordadas no momento da aplicação.
        </p>
        <p className={stylesTipografia.paragrafo}>
          Ao investir em um CDB, o investidor empresta seu dinheiro ao banco por
          um determinado prazo, que pode variar de acordo com o contrato.
          Durante esse período, o banco se compromete a remunerar o investidor
          com juros sobre o valor aplicado.
        </p>
        <p className={stylesTipografia.paragrafo}>
          Apesar de ser um investimento seguro, é importante destacar que o
          rendimento do CDB pode variar de acordo com o prazo de aplicação, o
          tipo de remuneração (pré ou pós-fixada), as taxas de mercado e a saúde
          financeira da instituição emissora.{' '}
        </p>
        <h2 className={stylesTipografia.h2}>Quanto Rende PicPay?</h2>
        <p className={stylesTipografia.paragrafo}>
          É importante ressaltar que os rendimentos oferecidos pelo PicPay estão
          sujeitos a variações e podem ser impactados por diversos fatores
          externos, como mudanças na economia e na política monetária. Portanto,
          é fundamental que os usuários estejam cientes dos riscos envolvidos ao
          investir seu dinheiro em qualquer plataforma financeira.
        </p>
        <h2 className={stylesTipografia.h2}>Outros Benefícios PicPay</h2>
        <p className={stylesTipografia.paragrafo}>
          Além das informações já fornecidas sobre o PicPay, há alguns aspectos
          adicionais que podem ser úteis para entender melhor essa plataforma de
          pagamento digital:
        </p>
        <ul className={stylesLista.checkList}>
          <li>
            Recarga de Celular: O PicPay permite que os usuários recarreguem
            créditos para celulares pré-pagos de diversas operadoras de
            telefonia móvel diretamente pelo aplicativo, de forma rápida e
            conveniente.
          </li>
          <li>
            Pagamento de Contas: Uma das funcionalidades mais populares do
            PicPay é a possibilidade de pagar contas, como boletos bancários,
            faturas de serviços públicos, mensalidades escolares e outros tipos
            de cobranças, tudo de forma digital e sem precisar sair de casa.
          </li>
          <li>
            Compra de Créditos para Transporte: O aplicativo também oferece a
            opção de adquirir créditos para transporte público em diversas
            cidades do Brasil, facilitando o pagamento de passagens de ônibus,
            metrô, trens e outros meios de transporte, tudo de forma integrada e
            prática.
          </li>
          <li>
            Cashback e Promoções: O PicPay frequentemente oferece promoções e
            programas de cashback, onde os usuários podem receber parte do valor
            gasto em suas compras de volta na forma de créditos no aplicativo,
            incentivando o uso da plataforma e proporcionando benefícios
            adicionais aos clientes.
          </li>
          <li>
            Serviços Financeiros: Além das opções de investimento mencionadas
            anteriormente, o PicPay também vem expandindo sua atuação para
            oferecer outros serviços financeiros, como empréstimos, seguros e
            até mesmo uma carteira digital para armazenamento de dinheiro e
            realização de transferências entre usuários.
          </li>
          <li>
            Cartão de Crédito PicPay: Recentemente, o PicPay lançou seu próprio
            cartão de crédito, que oferece uma série de vantagens e benefícios
            aos usuários, como cashback em todas as compras, isenção de anuidade
            e a possibilidade de pagar a fatura diretamente pelo aplicativo,
            tornando o controle financeiro ainda mais simples e transparente.
          </li>
        </ul>
        <p className={stylesTipografia.paragrafo}>
          Estes são apenas alguns dos aspectos complementares que podem ser
          aproveitados no PicPay, uma plataforma em constante desenvolvimento,
          que procura proporcionar continuamente mais serviços e comodidades aos
          seus utilizadores.
        </p>
        <h2 className={stylesTipografia.h2}>Considerações Finais</h2>
        <p className={stylesTipografia.paragrafo}>
          {'Em resumo, o PicPay oferece aos seus usuários a oportunidade de          investir seus ' +
            params.valor +
            ' mil e obter rendimentos por meio de opções como o CDB e a Poupança. No entanto, é importante entender as características e os riscos associados a cada opção de investimento antes de tomar uma decisão.'}
        </p>
        <p className={stylesTipografia.paragrafo}>
          {'Para finalizar, realize uma análise cuidadosa de suas finanças pessoais e navegue em nosso site para entender mais de investimentos, pois compreender quanto rende ' +
            params.valor +
            ' mil no PicPay é apenas o primeiro passo para uma jornada financeira de sucesso.'}
        </p>
      </>
    );
  }

  return (
    <div className="App">
      <Head
        title={getTitle()}
        metaDescription={getTitle()}
        h1={getTitle()}
        canonical={
          'https://www.guardardinheiro.com.br/quanto-rende/' +
          params.valor +
          '/mil/' +
          params.produto
        }
        nivel="2"
        id_wp_post={2200}
      />

      <div>
        <Container>
          <BreadcumbH1 paginaAtual={getTitle()} texto={getTitle()} />

          <div className={stylePost.container}>
            <div className={stylePost.itemPost}>
              <AdsContent1 />
              <h1 className={stylesTipografia.h1}>{getTitle()}</h1>
              <Paragrafo
                texto={
                  'Simulador de Rendimento de ' +
                  params.valor +
                  ' Mil Reais em ' +
                  produto +
                  ' para Guardar Dinheiro com a Selic a 10,50% ao ano.'
                }
              />
              {getButtonSimulador()}

              <Paragrafo
                texto={
                  'O Simulador ' +
                  produto +
                  ', faz uma simulação de forma simples de quanto seu dinheiro vai render no ' +
                  produto +
                  ' no período informado.'
                }
              />
              <AdsContent2 />
              <Paragrafo
                texto={
                  'Essa funcionalidade ajuda muitos investidores iniciantes onde é melhor investir seu dinheiro e também a entender qual será a expectativa de retorno no futuro.'
                }
              />

              <Paragrafo
                texto={
                  'O prazo do investimento de ' +
                  params.valor +
                  ' Mil Reais em ' +
                  produto +
                  ' é crucial. Quanto mais tempo você mantiver seu dinheiro investido, maior será o rendimento devido aos juros compostos e também pagará menos imposto.'
                }
              />

              <Paragrafo
                texto={
                  'Nosso simulador ' +
                  produto +
                  ', também apresenta uma simulação dos melhores investimentos de renda fixa atualmente, ou seja, será possível comparar o rendimento de ' +
                  params.valor +
                  ' Mil Reais em ' +
                  produto +
                  ' com outras instituições financeiras e analisar qual possui o melhor rendimento no momento.'
                }
              />
              {getButtonSimulador()}

              <Paragrafo
                texto={
                  'Investir ' +
                  params.valor +
                  ' mil reais ' +
                  produto +
                  ' pode ser uma excelente opção para aqueles que buscam rendimentos atrativos e segurança financeira. Ao utilizar o simulador, você poderá visualizar claramente como seu dinheiro pode crescer ao longo do tempo, levando em conta fatores como juros compostos e taxas de retorno.'
                }
              />

              <Paragrafo
                texto={
                  'Investir em renda fixa atualmente vem sendo uma das escolhas mais populares devido à sua segurança e previsibilidade. Agora que você já conhece como funciona nosso simulador, acesse aqui O Simulador de ' +
                  getTitle() +
                  '.'
                }
              />
              {getButtonSimulador()}

              {params.produto === 'picpay' && retornaPicPay()}

              <br />
              <br />
              <br />
              <H2 texto="Dúvidas frequentes" />
              <ItemDuvida duvida="selic" />
              <ItemDuvida duvida="cdb" />
              <ItemDuvida duvida="lci" />
              <ItemDuvida duvida="lca" />

              <br />
            </div>
            {!mobile ? (
              <div className={stylePost.itemCategorias}>
                <Categorias />
              </div>
            ) : null}
          </div>
          <br />
        </Container>
      </div>

      {/* <hr></hr>
      <RatingStars
        texto={'O que achou da nossa página?'}
        pagina={'quanto-rende-rf'}
        title={getTitle()}
        metaDescription={getTitle()}
      /> */}
    </div>
  );
};

export default QuantoRendeMilPouso;
