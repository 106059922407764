import React, { useState, useEffect, useRef } from 'react';
import isMobile from '../Helper/Mobile';
import AdsAmpContent1 from '../AdsJoinAds/AdsAmpContent1';
import AdsFixedDesktop from '../AdsJoinAds/AdsFixedDesktop';
import AdsContent3 from '../AdsJoinAds/AdsContent3';

function AdsSticky(props) {
  const mobile = isMobile();
  const [isSticky, setIsSticky] = useState(true);
  const divRef = useRef(null);
  const [offsetTop, setOffsetTop] = useState(0);
  const [pixelsToScroll, setPixelsToScroll] = useState(
    props.pixels ? props.pixels : 350,
  );

  useEffect(() => {
    console.log(pixelsToScroll);

    const divElement = divRef.current;
    if (divElement) {
      setOffsetTop(divElement.offsetTop);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const divElement = divRef.current;
      if (divElement) {
        const { top } = divElement.getBoundingClientRect();

        if (
          parseFloat(window.scrollY) >=
          parseFloat(offsetTop) + pixelsToScroll
        ) {
          // console.log(parseFloat(window.scrollY));
          // console.log(parseFloat(offsetTop) + 500);
          setIsSticky(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [offsetTop]);

  function getAdsComponent() {
    if (props.ads === 'content1') {
      return <>{mobile ? <AdsAmpContent1 /> : <AdsFixedDesktop />}</>;
    } else if (props.ads === 'content3') {
      return <AdsContent3 />;
    }
  }

  return (
    <div
      style={{
        position: isSticky ? 'sticky' : 'relative',
        top: isSticky ? '55px' : 'auto',
        width: '100%',
        background: 'white',
        zIndex: '30',
        // height: '280px',
        // border: '0.5px solid #435d7d',
      }}
      ref={divRef}
    >
      {getAdsComponent()}
    </div>
  );
}

export default AdsSticky;
