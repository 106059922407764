import React from 'react';

const AdsNobeta1 = () => {
  React.useEffect(() => {
    var path = '/150684666,22523725657/guardardinheiro.iab-300x250-1';
    var size = [
      [250, 250],
      [300, 250],
    ];
    var id = 'nbt300x250-1';

    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];
    googletag.cmd.push(function () {
      var mapping = googletag
        .sizeMapping()
        .addSize([0, 0], ['fluid', [250, 250], [300, 250]])
        .build();

      googletag
        .defineSlot(path, size, id)
        .setTargeting('test', 'lazyload')
        .defineSizeMapping(mapping)
        .setCollapseEmptyDiv(true)
        .addService(googletag.pubads());

      googletag.pubads().enableLazyLoad({
        fetchMarginPercent: 10,
        renderMarginPercent: 6,
        mobileScaling: 2.0,
      });

      googletag.pubads().setCentering(true);
      googletag.enableServices();
    });
    googletag.cmd.push(function () {
      googletag.display(id);
    });
  }, []);

  return (
    <div
      id="nbt300x250-1"
      style={{ minWidth: '250px', minHeight: '250px' }}
    ></div>
  );
};
export default AdsNobeta1;
