import React from 'react';
// import AdsMGIDArtigo from '../AdsMGID/AdsMGIDArtigo';
import { Container } from 'react-bootstrap';
import isMobile from '../Helper/Mobile';
// import AdsMoneytizer1Billboard from '../AdsMoneytizer/AdsMoneytizer1Billboard';
// import AdsNobeta1 from '../AdsNobeta/AdsNobeta1';
// import AdsenseResponsivo1 from '../Adsense/AdsenseResponsivo1';
import AdsenseContent1 from '../Adsense/AdsenseContent1';
// import AdsContent5 from './AdsContent5';

const AdsContent1 = () => {
  const mobile = isMobile();
  const [content1Visible, setContent1Visible] = React.useState(true);
  React.useEffect(() => {
    var path = '/22523725657/guardardinheiro.com.br/Guardardinheiro_Content1';
    var size = [
      [250, 250],
      [300, 250],
      [336, 280],
    ];
    var id = 'Content1';

    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];
    googletag.cmd.push(function () {
      var mapping = googletag
        .sizeMapping()
        .addSize([0, 0], ['fluid', [250, 250], [300, 250], [336, 280]])
        .build();

      googletag
        .defineSlot(path, size, id)
        .defineSizeMapping(mapping)
        .setCollapseEmptyDiv(true)
        .addService(googletag.pubads());

      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
    });
    googletag.cmd.push(function () {
      googletag.display(id);
    });

    const checkContent1Visibility = () => {
      const content1Element = document.getElementById('Content1');
      if (content1Element) {
        setContent1Visible(
          window.getComputedStyle(content1Element).display !== 'none',
        );
      }
    };

    checkContent1Visibility();
    // Optionally, set up an interval or mutation observer to re-check visibility if it may change dynamically
    const intervalId = setInterval(checkContent1Visibility, 2800);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Container>
      <div id="Content1" style={{ textAlign: 'center' }}></div>
      {!content1Visible && (
        <div
        // style={{
        //   border: '1px solid #00bfff',
        // }}
        >
          {/* {mobile ? <AdsMoneytizer1Billboard /> : <AdsMGIDArtigo />} */}
          {/* <AdsenseResponsivo1 /> */}
          <AdsenseContent1 />
        </div>
      )}
    </Container>
  );
};
export default AdsContent1;
